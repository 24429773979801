
import React, { useState, useEffect, useCallback, lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { ContextProvider } from "./socket-context";
//import Header from "./components/header";
import Home from "./screens/home"; 
import { config } from './config.js' // component display user (see detail on /example directory)
import { CSSReset, GlobalStyle, Styles } from "./globalStyles.js"; 
//import Cookies from 'js-cookie';
//import styled,  { createGlobalStyle } from 'styled-components'
import { SocketContext } from "./socket-context.js";
//import { GoogleOAuthProvider } from "@react-oauth/google"

//import 'bootstrap/dist/css/bootstrap.min.css';

//import LoginScreen from 'src/screens/login-screen/index.js';
//import QRScanner from 'src/screens/qr-scanner/index.js'; 
//import Buttons from './screens/buttons/index.js'; 
//import ButtonsAR from 'src/screens/buttonsAR/index.js'; 
//import VideoCall from 'src/screens/video-call/index.js'; 
//import Profile from 'src/screens/profile/index.js'; 
//import Panel from './screens/panel'; 
import Form from './screens/form'; 

const LoginScreen = lazy(() => import('./screens/login/index.js'));
//const QRScanner = lazy(() => import('./screens/scanner/index.js')); 
//const Buttons = lazy(() => import('./screens/buttons')); 
//const Panel = lazy(() => import('./screens/panel')); 
//const ButtonsAR = lazy(() => import('./screens/buttonsAR/index.js')); 
//const VideoCall = lazy(() => import('./screens/video-call/index.js')); 
//const Profile = lazy(() => import('./screens/profile'));
//const Create = lazy(() => import('./screens/create'));
//const Call = lazy(() => import('./screens/call'));
 
//const helmetContext = {};

		//
		//</HelmetProvider>

const App = () => {	
	const isAuthenticated = true; // Replace with actual authentication check
    return (
		<HelmetProvider context={SocketContext}>			
			<ContextProvider>
				<CSSReset />
				<GlobalStyle />
				<div className='q-main-wrapper'>
					<Suspense fallback={
							<div className="loading-screen">
								<div className="loading-spinner">
								</div>
							</div>           			  
						}
					>
						<Router>
							{/*<Header /> */}
							<Switch>
								<Route path="/login" exact>
									<LoginScreen />
								</Route>
								<Route path="/" exact>
									<Home />
								</Route>
								<Route path="/xv_de_belu" exact>
									<Form />
								</Route>
								<Route path="/xv_de_kiara" exact>
									<Form />
								</Route>
								{/*<PrivateRoute path="/xv_de_belu" component={Form} isAuthenticated={isAuthenticated} />*/}
								{/*<Route path="/scan">
									<QRScanner/>
								</Route> 
								<Route path="/panel">
									<Panel user={'tito'}/>
								</Route>
								<Route path="/buttons">
									<Buttons user={'tito'}/>
								</Route>
								<Route path="/buttonsAR">
									<ButtonsAR />
								</Route>
								<Route path="/videocall">
									<VideoCall />
								</Route>
								<Route path="/call">
									<Call />
								</Route>
								<Route path="/create">
									<Create />
								</Route>
								<Route path="/profile">
									<Profile />
								</Route>*/} 
							</Switch>
						</Router>
					</Suspense>                            
				</div>				
			</ContextProvider>
		</HelmetProvider>
    );
};

function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
	return (
	  	<Route {...rest} render={(props) => (
			isAuthenticated ? <Component {...props} />
	  		: <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
	  	)}/>
	);
}

export default App;
