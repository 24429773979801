import React, { useRef, forwardRef, useMemo, useState, useEffect, useContext } from "react";
import { SEO } from '../../seo.js';
import styled, { keyframes } from "styled-components"
import { FaHome, FaList , FaUserCircle, FaTimes, FaArrowLeft, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { BsFilePersonFill } from "react-icons/bs";
import { IoIosLogIn } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { GiHamburgerMenu } from "react-icons/gi";
import { Menu as MenuInner, MenuItem, SubMenu, MenuButton, ControlledMenu, useClick, useMenuState } from '@szhsin/react-menu';
import {
    menuSelector,
    menuItemSelector,
    menuDividerSelector
  } from "@szhsin/react-menu/style-utils";
//import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import '@szhsin/react-menu/dist/index.css';
//import '@szhsin/react-menu/dist/transitions/slide.css';
import { Virtuoso } from 'react-virtuoso';
import fondo_mievento from '../../assets/fondo_mievento.jpg';
import inconstruction from '../../assets/party-construction.png';
import loading3 from '../../assets/loading3.png';
//import loading4 from '../../assets/loading4.png';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
//import loading4 from '../../assets/loading4.png';
import { SocketContext } from "../../socket-context.js";
import Cookies from "js-cookie";
import { config } from '../../config.js' // component display user (see detail on /example directory)
import dayjs from 'dayjs';
import _ from 'lodash';

import {
    LoginSocialGoogle,
    LoginSocialApple,
    LoginSocialAmazon,
    LoginSocialFacebook,
    LoginSocialGithub,
    LoginSocialInstagram,
    LoginSocialLinkedin,
    LoginSocialMicrosoft,
    LoginSocialPinterest,
    LoginSocialTwitter,
    IResolveParams,
} from 'reactjs-social-login';
  
import {
    GoogleLoginButton,
    AppleLoginButton,
    FacebookLoginButton,
    GithubLoginButton,
    AmazonLoginButton,
    InstagramLoginButton,
    LinkedInLoginButton,
    MicrosoftLoginButton,
    TwitterLoginButton,
} from 'react-social-login-buttons';

const REDIRECT_URI = window.location.href;
const REDIRECT_IG = 'https://mievent.info/';
const EVENT_URL = 'https://mievent.info/xv_de_belu/';
const weekdays = ["Domingo", "Lunes", "MArtes", "Miercoles", "Jueves", "Viernes", "Sábado"];
const monthnames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

/*
Arabescos
Sand&sun
Disco
Country
Abstract
Modern
Pop
*/

const itms = _.times(100, index =>  { return ({ name: String(_.random(500000000)) }) });
//console.log(itms.length + ' => ' + JSON.stringify(itms));
//var users = [];

const HomeScreen = () => {
    
    const [ loading, setLoading ] = useState(false);
    const [ sideBarOpen, setSideBarOpen ] = useState(false);
    const [ showLogin, setShowLogin ] = useState(false);
    const [ showConfirmationList, setShowConfirmationList ] = useState(false); 
    //const [ showConfirmationMenu, setShowConfirmationMenu ] = useState(false); 
    const [ items, setItems ] = useState([]); 
    const [ filteredItems, setFilteredItems ] = useState(null);     
    const [ itemCount, setItemCount ] = useState(0);     
    const [ showConfirmationMenu, setShowConfirmationMenu ] = useMenuState({ transition: true });
    const [ showEventMenu, setShowEventMenu ] = useMenuState({ transition: true });
    const [ showAdminMenu, setShowAdminMenu ] = useMenuState({ transition: true });
    const [ showEventList, setShowEventList ] = useState(false);
    const [ showAdminList, setShowAdminList ] = useState(false);
    const [ showProfile, setShowProfile ] = useState(false);
    const [ comment, setComment ] = useState('');

    const [ showCreateEvent, setShowCreateEvent ] = useState(false);
    const [ filter, setFilter ] = useState(null);
    //const navigate = useNavigate();
    const history = useHistory();

    //const menuRef = useRef(null);
    //const anchorProps = useClick(showConfirmationMenu.state, setShowConfirmationMenu);
    const {
        name,
        user, 
        setUser,
        profile, 
        setProfile,
        provider, 
        setProvider,
        event, 
        setEvent,
        eventData,
        setEventData,
        fetchCall
    } = useContext(SocketContext);

    /*const users = useMemo(() => {
        let confis = [];
        _.each(users, user => {
            let confi = _.find(user.events, event => { return event.url === EVENT_URL });
            if (confi) confis.push(confi);
        })
        return Array.from({ length: 100000 }, (_, index) => ({
            name: `User ${index}`,
            bgColor: `hsl(${Math.random() * 360}, 70%, 80%)`,
            size: Math.floor(Math.random() * 40) + 100,
            email: '',
            description: `Description for user ${index}`
        }))
    }, [])*/

    useEffect(() => { 
        //console.log('Do something after counter has changed', counter);
        /*if (!profile?.name) {
            let prf = Cookies.get('profile');
            if (prf?.name) {
                setProfile(profile);
            }
        }	    
        fetchCall({ data: { url: event_url }, endpoint: '/getEvent', tomsg: '5463', method: 'post', showmsg: 'Confirmando..', 
            onok: async responseData => {
                //console.log('/getEvent ' + JSON.stringify(responseData));                                
                if (responseData.success) {                                    
                } else {
                }                                        
            }, 
            onerr: err => {},
            usews: false
        });*/
    }, []);
    
    const onLoginStart = () => {

    }

    const onLogoutSuccess = () => {

    }

    const filterList = (filter) => {
        setFilter(filter);
        if (!filter) {
            setFilteredItems(items);    
            return;
        }
        setFilteredItems(_.reduce(items, (col, itm) => {
            if (filter.asistencia !== undefined) {
                //if ((filter.asistencia !== undefined) && (filter.asistencia !== itm.voy)) return col;
                if (!!filter.asistencia !== !!itm.voy) {
                    //console.log('filter.asistencia: ' + filter.asistencia + ' user.voy: ' + itm.voy);
                    return col;
                }
            }
            if ((filter.menu !== undefined) && (!itm.menu.includes(filter.menu))) return col;
            col.push(itm);
            return col;
        }, []))
    }

    const onReject = err => {
        setLoading(false);
        console.log(err);
    } 

    const closeSession = () => {
        setShowConfirmationList(false);
        setShowEventList(false);
        setProfile({}); 
        setUser({}); 
        setProvider(null);
    }

    const tryCreate = () => {
        let data = { name: profile.name, email: profile.email, profile };
        console.log('TryCreate: ' + JSON.stringify(data));
        setLoading(true);
        fetchCall({ data, endpoint: '/tryCreate', tomsg: '5479', method: 'post', showmsg: 'Buscando..', 
            onok: async responseData => {
                setLoading(false);
                console.log('/tryCreate ' + JSON.stringify(responseData));                                                                    
            }, 
            onerr: err => {
                setLoading(false);
            },
            usews: false
        });
    }

    const _sendComment = () => {
        if (comment.length > 3) {
            let data = { name: profile.name, email: profile.email, profile, comment };
            console.log('_sendComment: ' + JSON.stringify(data));
            setLoading(true);
            fetchCall({ data, endpoint: '/addComment', tomsg: '5480', method: 'post', showmsg: 'Buscando..', 
                onok: async responseData => {
                    setLoading(false);
                    console.log('/addComment ' + JSON.stringify(responseData));                                                                    
                    setComment('');
                    setShowCreateEvent(false); 
                }, 
                onerr: err => {
                    setLoading(false);
                },
                usews: false
            });
        }
    }

    const getProfile = (data) =>{
        //setLoading(false);
        console.log('PROVIDER: ' + JSON.stringify(provider));
        console.log('DATA: ' + JSON.stringify(profile));
        Cookies.set('profile', profile);                                          
        /*fetchCall({ data: { token: data.credential }, endpoint: '/verifyToken', tomsg: '5467', method: 'post', showmsg: 'Verificando..', 
            onok: async responseData => {
                console.log('/verifyToken ' + JSON.stringify(responseData));                                                                    
                if (responseData.success) {                                    
                } else {
                    //Alert.alert( 'Error: ', 'Usuario no encontrado. ', [{ text: 'Aceptar', onPress: () => {} }]);														                    
                }                                        
            }, 
            onerr: err => {},
            usews: false
        });*/                                                                                                            
        setLoading(true);
        fetchCall({ data: { email: data.email, name: data.given_name, lastName: data.family_name }, endpoint: '/getUser', tomsg: '5472', method: 'post', showmsg: 'Buscando..', 
            onok: async responseData => {
                setLoading(false);
                console.log('/getUser ' + JSON.stringify(responseData));                                                                    
                if (responseData.success) {                                    
                    setUser(responseData.user);
                } else {
                    //Alert.alert( 'Error: ', 'Usuario no encontrado. ', [{ text: 'Aceptar', onPress: () => {} }]);														                    
                }                                        
            }, 
            onerr: err => {
                setLoading(false);
            },
            usews: false
        });
    }
    
    const getConfirmations = (url) =>{
        setLoading(true);        
        console.log('URL: ' + url + ' DATA: ' + JSON.stringify(profile));
        //fetchCall({ data: { url: EVENT_URL, email: profile.email }, endpoint: '/getConfirmations', tomsg: '5469', method: 'post', showmsg: 'Buscando..', 
        fetchCall({ data: { url, email: profile.email }, endpoint: '/getConfirmations', tomsg: '5469', method: 'post', showmsg: 'Buscando..', 
            onok: async responseData => {
                setLoading(false);
                setItems([]);
                setFilteredItems([]);
                console.log('/getConfirmations ' + JSON.stringify(responseData));                                                                    
                if (responseData.success) {                                    
                    let confis = [];
                    _.each(responseData.users, user => {
                        let confi = _.find(user.events, evnt => { return evnt.url === url });
                        if (confi) {
                            confi.name = user.name;
                            confi.email = user.email[0];
                            confis.push(confi);
                        }
                    })                
                    setItems(confis);
                    setFilteredItems(confis);
                } else {
                    //Alert.alert( 'Error: ', 'Usuario no encontrado. ', [{ text: 'Aceptar', onPress: () => {} }]);														                    
                }                                        
            }, 
            onerr: err => {
                setLoading(false);
            },
            usews: false
        });                                                                                                            
    }
   
    const _downloadPDFFile = () => {
		console.log('items: ' + items.length);
		var dn = dayjs().format('DD-MM-YYYY');
		var filename = 'Confirmaciones_' + eventData.event.name + '_al_' + dn + ' .pdf';
        const headers = [
            { name: 'nro', key: 'Nro', width: 10, align: 'center' }, 
            //{ name: 'name', key: 'Nombre', width: 30 },  
            { name: 'email', key: 'Email', width: 50 },  
            { name: 'go', key: 'Voy', width: 10, align: 'center' },  
            //{ name: 'reason', key: 'Razón', width: 30 },  
            { name: 'menu', key: 'Menu', width: 10, align: 'center'},  
            //{ name: 'options', key: 'options', width: 10, align: 'center'},  
            { name: 'date', key: 'Fecha', width: 12 } 
        ].map((key, index) => {
            return ({
                id: key.name,
                name: key.name,
                prompt: key.key,
                width: key.width,
                align: key.align || 'left',
                padding: 0
            });
        });
        var pdf = new jsPDF({ putOnlyUsedFonts: true });
        var colData = _.transform(items, (res, itm) => { 
            let id = (res.length + 1).toString();
            res.push({ 
                nro: id,
                name: itm.name,
                email: itm.email,
                go: itm.voy ? 'Si!': 'No',
                reason: itm.voy ? itm.reason: '---',
                menu: itm.menu,
                options: JSON.stringify(_.keys(itm.options)),
                date: dayjs(itm.date_confirm).format('DD/MM/YYYY'),
        })}, []);
        console.log('HDRS: ' + JSON.stringify(headers) + '\nDATA: ' + JSON.stringify(colData));        
        pdf.table(1, 1, colData, headers, { printHeaders: true, autoSize: true });        
        pdf.save(filename);
    }

	const _downloadXLSFile = () => {
        let xlsRows = items;
		//console.log('xlsRows: ' + JSON.stringify(xlsRows, null, 2));
		console.log('xlsRows: ' + xlsRows.length);
		//var dn = Date.now();
		var dn = dayjs().format('DD-MM-YYYY');
        /* File Name */
		var filename = 'Confirmaciones_' + eventData.event.name + '_al_' + dn + ' .xlsx';
        var createXLSLFormatObj = [];
        /* XLS Head Columns */
        var xlsHeader = ['Nro', 'Nombre', 'Email', 'Voy', 'Razón', 'Menu', 'Opciones'];
        /* XLS Rows Data */
        createXLSLFormatObj.push(xlsHeader);
        _.forEach(xlsRows, (val, index) => {
            createXLSLFormatObj.push([index + 1, val.name, val.email, val.voy ? 'Si!':'No', val.voy ? '---': val.reason, val.menu, dayjs(val.date_confirm).format('DD/MM/YYYY')]);
        });
        /* Sheet Name */
        var ws_name = "Confirmaciones";
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);			
		var wscols = [
			{ wch:10 },
			{ wch:20 },
			{ wch:30 },
			{ wch:5 },
			{ wch:20 },
			{ wch:20 },
			{ wch:20 }
		];
		ws['!cols'] = wscols;	
        /*_.forEach(xlsRows, (val, idx) => {		
			var addr = XLSX.utils.encode_cell({r: idx, c: 7});
			let url = ws[addr];			
			url.l = { Target: url.v, Tooltip: 'Abrir mapa' };
        });*/		
        /* Add worksheet to workbook */
        XLSX.utils.book_append_sheet(wb, ws, ws_name);
        /* Write workbook and Download */
        XLSX.writeFile(wb, filename);		
	}

    const _menuAction = menu => {
        switch(menu) {
            case 'create':
                if (profile.email) {
                    tryCreate();                    
                    setComment('');
                    setShowProfile(false); setShowEventList(false); setShowAdminList(false); setShowCreateEvent(true);                            
                } else { 
                    setShowLogin(true);
                }
                break;
            case 'invites':
                setShowCreateEvent(false); setShowProfile(false); setShowAdminList(false); setShowEventList(true); 
                break;
            case 'eventos':
                setShowCreateEvent(false); setShowProfile(false); setShowEventList(false); setShowAdminList(true);
                break;
            case 'profile':
                setShowCreateEvent(false); setShowAdminList(false); setShowEventList(false);setShowProfile(true);
                break;
            default:
        }
    }

    return (
        <MainHome style={{   }}>
            <SEO
                title='Mievent.info'
                description='Save the date interactivo'
                name='Mievent.info'
                type='article'
            />                            
            <img src={fondo_mievento}
                style={{ width: '100vw' }} 
                alt='MIEVENT.INFO'
            />
            <div style={{ position: 'absolute', top: '70vh', left: 0, right: 0, display: 'flex', 
                        flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}
            >
                <h3 style={{ fontFamily: 'Bebas Neue', fontSize: '8vh', color: '#eee' }}>
                    MIEVENT.INFO
                </h3>
                <Abutton
                    style={{ display: 'flex', textTransform: 'none', marginTop: '3vh', fontWeight: 'bold', minWidth: '50vw', height: '5vh',
                            //backgroundColor: '#00ee0050' 
                        }}
                    onClick={() => {
                        //window.location.href = '/create';
                        /*if (profile.email) {
                            setShowProfile(false); 
                            setShowEventList(false); 
                            setShowAdminList(false); 
                            setShowCreateEvent(true);                            
                        } else { 
                            setShowLogin(true);
                        }*/
                        _menuAction('create');
                    }}
                >
                    {/*<div style={{ position: 'absolute', top: '-2vh', left: '-2vh', right: '-2vh', bottom: '-2vh', backgroundColor: '#000000aa' }}/>*/}
                    <h3 style={{ fontSize: '3vh', fontWeight: 'bold', color: '#eee', marginTop: '4px' }}>
                        CREÁ TU SAVE THE DATE
                    </h3>
                </Abutton>
            </div>
            { showLogin &&  
                <Panel key='login' style={{ }}>
                    <br />
                    <PanelLine $fs='4vh' $fn={'Bebas Neue'} $color={'#eee'} style={{ }}><p>Identificate</p></PanelLine>                    
                    <FaTimes size={20} className='closeTimes' style={{  }}
                        onClick={() => {
                            setShowLogin(false);
                        }}
                    />
                    <br />
                    <LoginSocialGoogle
                        //client_id={process.env.REACT_APP_GG_APP_ID || ''}
                        client_id={config.REACT_APP_GG_APP_ID || ''}
                        onLoginStart={onLoginStart}
                        redirect_uri={REDIRECT_URI}
                        //typeResponse="idToken"
                        //typeResponse="accessToken"
                        //isOnlyGetToken 
                        scope="openid profile email"
                        discoveryDocs="claims_supported"
                        //access_type="offline"
                        ux_mode="popup"                        
                        onResolve={({ provider, data }) => {
                            console.log('PROVIDER: ' + provider + '\nDATA: ' + JSON.stringify(data));
                            setProvider(provider);
                            setProfile(data);  
                            getProfile(data);
                            setShowLogin(false); 
                        }}
                        onReject={onReject}
                    >
                        <GoogleLoginButton className='btn-social' onClick={() => {
                            setLoading(true);
                        }}>
                            <span>con Google</span>
                        </GoogleLoginButton>                        
                    </LoginSocialGoogle>
                    <br />
                    {/*<LoginSocialInstagram
                        client_id={config.REACT_APP_INSTAGRAM_APP_ID || ''}
                        client_secret={config.REACT_APP_INSTAGRAM_APP_SECRET || ''}
                        //redirect_uri={REDIRECT_IG}
                        //redirect_uri={REDIRECT_URI}                        
                        //redirect_uri={config.REACT_APP_INSTAGRAM_REDIRECT_URL}
                        //redirect_uri={'https://mievent.backloop.dev/xv_de_belu'}
                        redirect_uri={'https://mievent.info/xv_de_belu'}
                        onLoginStart={onLoginStart}
                        onLogoutSuccess={onLogoutSuccess}
                        onResolve={({ provider, data }) => {
                            setProvider(provider);
                            setProfile(data);
                            setShowLogin(false); 
                            getProfile();
                        }}
                        onReject={onReject}
                    >
                        <InstagramLoginButton className='btn-social'>
                            <span>con Instagram</span>
                        </InstagramLoginButton>
                    </LoginSocialInstagram>
                    <br />
                    <LoginSocialApple
                        client_id={config.REACT_APP_APPLE_ID || ''}
                        scope={'name email'}
                        redirect_uri={REDIRECT_URI}
                        onLoginStart={onLoginStart}
                        onResolve={({ provider, data }) => {
                            setProvider(provider);
                            setProfile(data);
                            setShowLogin(false); 
                            getProfile();
                        }}
                        onReject={onReject}
                    >
                        <AppleLoginButton className='btn-social'>
                            <span>con Apple</span>
                        </AppleLoginButton>
                    </LoginSocialApple>*/}                    
                    <br />
                </Panel>
            }
            { showEventList &&  
                <Panel key='eventList' style={{ }}>
                    <br />
                    <PanelLine $fs='4vh' $fn={'Bebas Neue'} $color={'#eee'} style={{ }}><p>Invitaciones</p></PanelLine>                                        
                    {/*<Menu menuClassName='eventMenu'
                        menuButton={
                            <div className='burgerConfirm'>
                                <GiHamburgerMenu 
                                    onClick={() => { setShowEventMenu(!showEventMenu) }}
                                />
                            </div>
                        }>
                        <MenuItem>Eliminar evento</MenuItem>
                        <MenuItem>Crear nuevo evento</MenuItem>
                    </Menu>*/}                        
                    <FaTimes size={20} className='closeTimes' style={{  }}
                        onClick={() => { 
                            setShowEventList(!showEventList) 
                        }}
                    />
                    <Virtuoso style={{ height: '50vh',  width: '80vw', maxWidth: '80vw', marginBottom: '30px',
                                        fontSize: '20px', fontFamily: 'Bebas Neue', fontWeight: 200
                    }}
                        data={user.events}
                        itemContent={(_, ev) => {
                            let after = dayjs(ev.date).isAfter(dayjs());
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',        
                                        justifyContent: 'space-between',
                                        //alignContent: 'space-between',        
                                        alignIitems: 'center',                              
                                        backgroundColor: after ? '#77e': '#55a',
                                        color: '#bbb',
                                        padding: '0.5rem',
                                        paddingLeft: '5px',
                                        paddingRight: '5px',
                                        margin: '5px',
                                        borderWidth: '1px',
                                        borderColor: '#eee',
                                        borderRadius: '10px',
                                        fontSize: '20px',
                                        //width: '100%'
                                        //height: '50px',
                                    }}
                                    onClick={() => { 
                                        /*setShowEventList(false);
                                        setItems([]);
                                        setEvent(ev.url); 
                                        //console.log('event click getConfirmations: ' + event.url);
                                        getConfirmations(ev.url);
                                        setShowConfirmationList(true);*/             
                                        if (after) {
                                            setEvent(ev.url); 
                                            let xurl = new URL(ev.url);
                                            console.log( 'URL: ' + ev.url + ' provider: ' + provider + ' after: ' + after);
                                            //window.location.href = '/create';
                                            //window.location.href = xurl.pathname;
                                            history.push(xurl.pathname);
                                        }
                                    }}
                                >
                                    <p className='pline' style={{ color: '#eee' }}><strong>{_ + 1}: {ev.name}</strong></p>
                                    <p className='pline' style={{ color: '#eee', backgroundColor: '#595' }}><strong>{after && ' Reconfirmar'} </strong></p>
                                </div>
                            )
                        }}
                    />                    
                </Panel>
            }

            { showAdminList &&  
                <Panel key='adminList' style={{ }}>
                    <br />
                    <PanelLine $fs='4vh' $fn={'Bebas Neue'} $color={'#eee'} style={{ }}><p>Mis eventos</p></PanelLine>                                        
                    <Menu menuClassName='adminMenu'
                        menuButton={
                            <div className='burgerConfirm'>
                                <GiHamburgerMenu 
                                    onClick={() => { setShowAdminMenu(!showAdminMenu) }}
                                />
                            </div>
                        }>
                        <MenuItem>Eliminar evento</MenuItem>
                        <MenuItem>Crear nuevo evento</MenuItem>
                    </Menu>                        
                    <FaTimes size={20} className='closeTimes' style={{  }}
                        onClick={() => { 
                            setShowAdminList(!showAdminList) 
                        }}
                    />
                    <Virtuoso style={{ height: '50vh',  width: '80vw', maxWidth: '80vw', marginBottom: '30px',
                                        fontSize: '20px', fontFamily: 'Bebas Neue', fontWeight: 200
                    }}
                        data={user.admin}
                        itemContent={(_, ev) => (
                            <div
                                style={{
                                    backgroundColor: '#77e',
                                    color: '#bbb',
                                    padding: '0.5rem',
                                    margin: '10px',
                                    borderWidth: '1px',
                                    borderColor: '#eee',
                                    borderRadius: '10px',
                                    fontSize: '20px'
                                    //height: '50px',
                                }}
                                onClick={() => { 
                                    setShowAdminList(false);
                                    setItems([]);
                                    setFilter(null);
                                    setFilteredItems([]);
                                    //setEvent(ev.url); 
                                    setEventData({ event: ev });
                                    //console.log('event click getConfirmations: ' + event.url);
                                    getConfirmations(ev.url);
                                    setShowConfirmationList(true);             
                                }}
                            >
                                <p className='pline'><strong>{_ + 1}: {ev.name}</strong></p>
                            </div>
                        )}
                    />                    
                </Panel>
            }
            { showConfirmationList &&  
                <Panel key='list' style={{ }}>
                    <br />
                    <PanelLine $fs='4vh' $fn={'Bebas Neue'} $color={'#eee'} style={{ }}><p>{filteredItems?.length} {filter?.title || 'confirmados'}</p></PanelLine>                                        
                    <Menu menuClassName='confiMenu'
                        menuButton={
                            <div className='burgerConfirm'>
                                <GiHamburgerMenu 
                                    onClick={() => { setShowConfirmationMenu(!showConfirmationMenu) }}
                                />
                            </div>
                        }>
                        <SubMenu label='Filtrar'>
                            <MenuItem onClick={ e => { filterList(null); }}>Todos</MenuItem>
                            <MenuItem onClick={ e => { filterList({asistencia: true, title: 'asistirán'}); }}>Asistirá: Si</MenuItem>
                            <MenuItem onClick={ e => { filterList({asistencia: false, title: 'no asistirán'}); }}>Asistirá: No</MenuItem>
                            <MenuItem onClick={ e => { filterList({menu: 'Celíaca', title: 'celíacos'}); }}>Menú: Celíaco</MenuItem>
                            <MenuItem onClick={ e => { filterList({menu: 'Vegana', title: 'veganos'}); }}>Menú: Vegano</MenuItem>
                            <MenuItem onClick={ e => { filterList({menu: 'Vegetariana', title: 'vegetarianos'}); }}>Menú: Vegetariano</MenuItem>
                            <MenuItem onClick={ e => { filterList({menu: 'De todo!', title: 'comen de todo'}); }}>Menú: De todo</MenuItem>
                        </SubMenu>
                        <SubMenu label='Bajar lista'>
                            <MenuItem onClick={e => { _downloadXLSFile(); }}>En XLS</MenuItem>
                            <MenuItem onClick={e => { _downloadPDFFile(); }}>En PDF</MenuItem>
                        </SubMenu>                            
                    </Menu>                        
                    <FaTimes size={20} className='closeTimes' style={{  }}
                        onClick={() => { 
                            setShowConfirmationList(false) 
                            setShowAdminList(true);
                        }}
                    />
                    <Virtuoso style={{ height: '50vh',  width: '80vw', maxWidth: '80vw', marginBottom: '30px',
                                        fontSize: '20px', fontFamily: 'Bebas Neue', fontWeight: 200
                    }}
                        data={filteredItems}
                        itemContent={(_, user) => {
                            let name = user.name || user.email;
                            return (
                                <div
                                    style={{
                                        backgroundColor: user.voy ? '#8e8': '#b78',
                                        color: '#bbb',
                                        padding: '0.5rem',
                                        margin: '10px',
                                        borderWidth: '1px',
                                        borderColor: '#eee',
                                        borderRadius: '10px',                                        
                                        //height: '50px',
                                    }}
                                >
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                        <p className='pline'><strong>{1+_}:</strong></p>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                            <p className='pline'><strong>{name} {user.voy ? 'Va!': 'No va'}</strong></p>
                                            { name !== user.email && <p className='pline' style={{fontSize: '16px' }}>{user.email}</p> }
                                        </div>
                                    </div>
                                    { user.voy &&
                                        <div style={{ color: '#bbb', 
                                                marginTop: '3px',
                                                paddingLeft: '5px',
                                                minHeight: '30px',
                                                lineHeight: '30px',
                                                //overflow: 'hidden',
                                                overflowWrap: 'break-word',
                                                inlineSize: '100%',
                                                //maxWidth: '100%',
                                                fontSize: '20px', 
                                                backgroundColor: user.menu.includes('Vegetarian') ? '#252': 
                                                    user.menu.includes('Vegan') ?  '#565':
                                                    user.menu.includes('Celíac') ? '#882': '#735'
                                            }}
                                        >                                            
                                            Menu: { user.menu }
                                        </div>
                                    }
                                    { !user.voy && <p className='pline'><strong>Razón: {user.reason}</strong></p> }
                                </div>                                
                            )
                        }}
                    />                    
                </Panel>
            }
            { showCreateEvent && 
                <Panel key='createEvent' style={{ }}>
                    <br />
                    <PanelLine $fs='4vh' $fn={'Bebas Neue'} $color={'#eee'} style={{ }}><p>Crear evento</p></PanelLine>                                        
                    <FaTimes size={20} className='closeTimes' style={{  }}
                        onClick={() => { 
                            setShowCreateEvent(false);
                        }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                        height: '50vh',  width: '80vw', maxWidth: '80vw', marginBottom: '10px'                                        
                    }}>
                        <img src={inconstruction} alt='In construction...' style={{ height: '30vw' }}/>
                        <p className='pline' style={{ fontSize: '4vh', fontFamily: 'Bebas Neue', fontWeight: 200, marginTop: '4vh', color: '#eee' }}><strong>Proximamente..</strong></p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',
                                    width: '80vw', paddingTop: '1vh', paddingBottom: '3vh'}}>
                        <input className='w3-input w3-round-large w3-hover-blue' 
                            value={comment}
                            type='text'
                            style={{ width: '70%', fontSize: '16px', height: '40px' }}
                            placeholder='Comentarios...' 
                            onChange={e => { setComment(e.target.value); }}
                        />
                        <div className="w3-round-large w3-hover-blue" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', 
                            width: '7vh', height: '40px', marginLeft: '5px', background: '#955'}}
                            onClick={() => { _sendComment(); }}    
                        >
                            <p style={{ fontSize: '2vh', fontFamily: 'Bebas Neue', fontWeight: 200, color: '#eee' }}>Enviar</p>    
                        </div>
                    </div>
                </Panel>
            }
            { showProfile && 
                <Panel key='profile' style={{ }}>
                    <br />5
                    <PanelLine $fs='4vh' $fn={'Bebas Neue'} $color={'#eee'} style={{ }}><p>Perfil</p></PanelLine>                                        
                    <FaTimes size={20} className='closeTimes' style={{  }}
                        onClick={() => { 
                            setShowProfile(false);
                        }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                        height: '50vh',  width: '80vw', maxWidth: '80vw', marginBottom: '30px'                                        
                    }}>
                        <img src={inconstruction} alt='In construction...' style={{ height: '20vw' }}/>
                        <p className='pline' style={{ fontSize: '4vh', fontFamily: 'Bebas Neue', fontWeight: 200, marginTop: '4vh', color: '#eee' }}><strong>En construcción..</strong></p>
                    </div>
                </Panel>
            }
            <Header $open={sideBarOpen}>                
                {/*<GiHamburgerMenu size={20} className='sideBarBurger' style={{ }}
                    onClick={() => {
                        //setSideBarOpen(!SideBarOpen);
                    }}
                />
                <FaList size={20} className='sideBarConfirmationList' style={{  }}
                    onClick={() => { 
                        setItems([]);
                        getConfirmations();
                        setShowConfirmationList(true); 
                    }}
                />*/}
                {/*<FaUserCircle size={20} className='sideBarLogin' style={{  }}
                    onClick={() => {
                        //setSideBarOpen(!SideBarOpen);
                        setShowLogin(true);
                        //console.log('loading: ' + !loading);
                        //setLoading(!loading);
                    }}
                />*/}
                <Menu menuClassName='adminMenu' //menuClassName='userMenu'
                    menuButton={
                        <div className='sideBarLogin'>
                            <FaUserCircle size={20} style={{ width: '4vh', height: '4vh' }}
                                //onClick={() => { setShowConfirmationMenu(!showConfirmationMenu) }}
                            />
                        </div>
                    }> 
                    { profile.email ? <>
                            <MenuItem onClick={e => { _menuAction('invites'); }}>Invitaciones</MenuItem>                                
                            <MenuItem onClick={e => { _menuAction('eventos'); }}>Mis eventos</MenuItem>
                            <MenuItem onClick={e => { _menuAction('create'); }}>Crear evento</MenuItem>
                            <MenuItem onClick={e => { _menuAction('profile'); }}>Perfil</MenuItem>
                            <MenuItem onClick={e => { closeSession(); }}>Salir</MenuItem>
                        </>:
                        <>
                            <MenuItem onClick={e => { setShowLogin(true); }}>Ingresar</MenuItem>
                        </>
                    }
                </Menu>                        
                <Lding $rota={loading}>
                    <img src={loading3} alt='Loading...' style={{ width: '4vh', height: '4vh' }}/>                    
                </Lding>
                {/*<Title txt={''} />*/}
                <div className='sideBarEmail' style={{ }}>
                    {profile.name}
                </div>
                {/* SideBarOpen && <div className='bar'>
                    <img src={icon_home} alt='main' onClick={() => goHome()}/>
                    <img src={icon_fingerprint} alt='login' onClick={() => goProg('login')} />
                    <img src={icon_check} alt='confirm' onClick={() => goProg('confirm')} />
                    <img src={icon_food} alt='dieta' onClick={() => goProg('dieta')} />
                    {eventData.options && eventData.options.length > 0 && 
                        <img src={icon_options} alt='options' onClick={() => goProg('options')} />
                    }
                    { eventData.gift.enable && eventData.gift.alias && <img src={icon_gift} alt='gift' onClick={() => goProg('gift')}/> }
                    { eventData.cfg.sign && <img src={icon_signature} alt='sign' onClick={() => goProg('sign')}/>}
                    <img src={icon_dress_code} alt='te_espero'/>
                </div>}*/}
            </Header>
        </MainHome>
    );
};

const Abutton = props => {
    return (
        <AButton {...props} >
            {/*<img src={img_button} style={{ display: 'flex', width: '300px', minWidth: '300px', height: '5.5vh', maxHeight: '8vh' }} alt=''/>*/}                        
            <label >
                {props.children}
            </label>            
        </AButton>
    )
}

const cs = { n: '7vw', s: '3vw', t: '8vw', w: '15vw', h: '15vw', r: '2vw', col: '#fff', bkg: '#000', is: '7vw' };

const rotate = keyframes`
    to { transform: rotate(360deg); }
`

const breatheAnimation = keyframes`
    0% { opacity: 0.3 } //.7
    //20% { opacity: 0.5; }  //.85
    //30% { opacity: 1; }
    50% { opacity: 1; }
    //60% { opacity: 1; }
    //70% { opacity: 0.5; }
    100% { opacity: 0.3; }
`

const MainHome = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #000;
    justify-content: center; //flex-start;
    align-items: center;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
`;

const Header = styled.div`  //div className="w3-sidebar w3-bar-block w3-black" 
    position: absolute;
    left: 0;    
    top: 0;
    //bottom: ${props => props.$open ? 0: 'undefined'};
    //height: ${props => props.$open ? '100vh': '16vh'};
    height: 6vh;
    width: 100vw;
    //padding-top: 10px;
	display: flex;
	flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    z-index: 1000;
    background-color: #4444ee;
    .sideBarBurger { 
        position: absolute;
        top: 1vh;
        left: 1vh;
        //color: #B056FF;
        color: #eee;
        //width: ${cs.is};
        //height: ${cs.is}; 
        width: 4vh;
        height: 4vh; 
        transform: { rotate:  ${props => props.$open ? '0deg': '90deg' }};         
    }
    .userMenu {
        //border: 2px solid green;
        border-radius: 10px;
    }
    .sideBarEmail {
        display: flex;        
        color: #eee;
        height: 6vh;
        font-family: Bebas Neue;
        font-size: 3vh;
        line-height: 6vh;        
    }
    .sideBarList {
        position: absolute; 
        top: 1vh;
        right: 1vh;
        color: #B056FF;
        width: 4vh;
        height: 4vh; 
        //color: #eee;
    }
    .sideBarLogin {
        position: absolute;
        top: 1vh;
        right: 1vh;
        //color: #B056FF;
        color: #eee;
        width: 4vh;
        height: 4vh; 
    }
    .sideBarConfirmationList {
        position: absolute;
        top: 1vh;
        right: 6vh;
        color: #B056FF;
        width: 4vh;
        height: 4vh; 
        //color: #eee;
    }
`;

const Lding = styled.div`
    position: absolute;
    top: 1vh;
    left: 1vh;
    width: 4vh;
    height: 4vh; 
    color: #eee;
    animation: ${rotate} 1s linear infinite;
    //color: ${props => props.move === 'true' ? '#eee': '#eee11100'};
    display: ${props => props.$rota ? 'block': 'none'};
`;

const AButton = styled.div`
    display: flex;
    flex-direction: column; 
    justify-content: center;
    //align-content: center;
    align-items: center;
    //position: relative;
    //min-width: 60vw;    
    //top: 40px;
    //height: calc(100vh - 40px);
    //background-color: #2ee;
    //color: #b056FF;
    //background-color: #00000080;
    color: #FFF;
    //font-family: Short Stack;
    font-family: Sofia;
    //font-family: Bebas Neue;
    font-size: 20px;
    &:hover {
        cursor: pointer;
        color: #201658;
        //background: #eee;
    }
    label {
        position: absolute;
        //position: relative;
        //transform: { translateY: 3vh; };
        animation-name: ${props => props.$quiet ? '': breatheAnimation};    
        animation-duration: 1.8s;
        animation-iteration-count: infinite;    
        //top: -5.5vh;
    }
`;

const PanelLine = styled.div` 
    //width: 50%;
    display: flex;    
    align-content: center;
    align-items: center;    
    justify-content: center;	
    flex-direction: column;        
    font-family: ${props => props.$fn || 'Sofia'};
    letter-spacing: 1.5px;
    //color: #eee;
    color:  ${props => props.$color || '#eee'};
    line-height: ${props => props.$fs || '4vw'};
    font-size: ${props => props.$fs || '4vw'};
`;

const Panel = styled.div`
    position: absolute;    
    margin: auto;
    //top: 30vh;
    //right: 0;
    //bottom: 0;
    //left: 0;
    min-width: 300px;
    //max-width: 350px;
    //max-height: 30%;
    font-size: 20px;
    display: flex;    
    flex-direction: column;        
	justify-content: center;	
    align-content: center;
    align-items: center;    
    border-radius: 1vh;
    //background-color: #555;
    background-color: #4444ee;
    color: #fff;
    label {
        margin: 20px;
    }
    .itm {
        //background-color: #3eddd2;
        display: flex;
        flex-direction: column;        
        align-content: center;
        align-items: center;
        justify-content: center;
    }
    .btn-social{
        min-width: 250px;
        width: 250px;
        //background: #7e2dd2;
    }        
    .closeTimes {
        position: absolute;
        top: 1vh;
        right: 1vh;
    }
    .burgerConfirm {
        position: absolute;
        top: 1vh;
        left: 1vh;
    }
    .confiMenu {
        //border: 2px solid green;
        border-radius: 10px;
    }
    .eventMenu {
        //border: 2px solid green;
        border-radius: 10px;
    }
    .itmrow {
        //background-color: #7e2dd2;
        display: flex;
        flex-direction: row;        
        align-content: space-between;        
        align-items: center;  
        height: 50px;      
        width: 70%;
        justify-content: space-between;        
    }
    .itmrow label {
        //background-color: #3e7742;
        line-height: 20px;
    } 
    .itmrow input {
        //background-color: #3e77d2;
        //line-height: 20px;
        margin-bottom: 10px;
    }
    .pline {
        color: #111;
        font-size: 20px;
    }        
`;

const Loading = styled.img`
    width: 10vh;
    position: absolute;
    bottom: 15vh;
    animation: ${rotate} 1s linear infinite;
    //animation-name: rotate;
    //animation-duration: 1s;
    //animation-iteration-count: infinite;    
`;

/*const Loading = styled.img`
    width: 7vh;
    position: absolute;
    bottom: 15vh;
    animation: ${rotate} 1s linear infinite;
    visibility: ${props => props.$move ? 'visible': 'hidden'};
    //animation-name: rotate;
    //animation-duration: 1s;
    //animation-iteration-count: infinite;    
`;

const Loading2 = styled.div`
    position: absolute;
    top: 46vh;
    bottom: 46vh;
    left: 46vw;
    right: 46vw;
    animation: ${rotate} 1s linear infinite;
    visibility: ${props => props.$move ? 'visible': 'hidden'};
    z-index: 1000;
    //animation-name: rotate;
    //animation-duration: 1s;
    //animation-iteration-count: infinite;    
    img {
        width: 8vw;
        height: 8vw;
        src: loading4;
        alt='Loading...' 
    }
`;*/

const Menu = styled(MenuInner)`
    ${menuSelector.name} {    
        box-sizing: border-box;
        z-index: 100;
        list-style: none;
        user-select: none;
        padding: 10px;
        font-size: 2rem;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-color: #33f;
        min-width: 10rem;
        color: #eee;
    }
    ${menuItemSelector.submenu} {
        font-size: 2rem;
        background-color: #33f;
        min-width: 10rem;
        color: #eee;
    }
  ${menuSelector.name}:focus,${menuItemSelector.name}:focus {
        background-color: #115;
        outline: none;
        color: #eee;
  }

`;

/*const Menu = styled(MenuInner)`
  ${menuSelector.name} {
    box-sizing: border-box;
    z-index: 100;
    list-style: none;
    user-select: none;
    padding: 10px;
    //font-size: 0.925rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #33f;
    min-width: 10rem;
    color: #eee;
  }

  ${menuSelector.name}:focus,${menuItemSelector.name}:focus {
    outline: none;
    color: #eee;
  }

`;*/

  /*${menuSelector.stateOpening} {
    animation: ${menuShow} 0.15s ease-out;
  }

  // NOTE: animation-fill-mode: forwards is required to
  // prevent flickering with React 18 createRoot()
  ${menuSelector.stateClosing} {
    animation: ${menuHide} 0.2s ease-out forwards;
  }

  ${menuItemSelector.name} {
    cursor: pointer;
    border-radius: 6px;
    padding: 0.375rem 0.625rem;
  }

  ${menuItemSelector.hover} {
    color: #fff;
    background-color: #59a2ff;
  }

  ${menuItemSelector.disabled} {
    cursor: default;
    color: #aaa;
  }

  ${menuItemSelector.submenu} {
    position: relative;
    &::after {
      content: url(${rightArrow});
      position: absolute;
      width: 7px;
      right: 0.625rem;
    }
  }

  ${menuDividerSelector.name} {
    height: 1px;
    margin: 0.5rem 0.625rem;
    background-color: rgba(0, 0, 0, 0.12);
  }*/

export default HomeScreen;
