
const DEFAULT_RANGE = 50

const defgroup = { 
	admins: [],	
	cfg: {
		name: '', 
		type: '',
		dire: '', 				
		geo: {},
		poly: {},		
		range: DEFAULT_RANGE,
		schedule: {},
	},			
	tags: { anon: {				//<- array de tags con permisos individuales		
		name: 'General',		//nombre
		schedule: '',
		sound: '',				//sonido distintivo
		ring: true,				//servicios habilitados
		chat: true,				//servicios habilitados
		audio: false,			//servicios habilitados
		video: false,			//servicios habilitados
		//range: true,			// <- debe estar en rango
		reqPic: true,			// <- debe tener o enviar foto
		reqAudio: true,			// <- debe tener audio
		reqVideo: true,			// <- debe tener video 
		reqLogin: true,			// <- pide login para continuar
		//tag: true,			// <- key del array de keys, si se exige cada una tiene permisos, default "anon" 
	}},					
	forward: {					//reenvio a servicios externos
		sendUrl: '', 
		sendPort: '', 
		sendMode: '', 
		sendproto: '', 
	},
	levels: [],					//ufs relacionadas				
	actions: [],				//se ejecutan ante eventos seleccionados
	emernumbers: {},
	alta: 0,
	baja: 0,					//<- > 0 = deleted
};

const vh2px = (vh=50) => {
    const oneVhInPx = window.innerHeight / 100;
    return oneVhInPx * vh;
};

const vw2px = (vw=50) => {
    const oneVwInPx = window.innerWidth / 100;
    return oneVwInPx * vw;
};

export const config = {
	googleapi: 'AIzaSyChtqlRrm2PV0bEg1Euk3p7sS2VrSzesNU',
	REACT_APP_GG_APP_ID: '130226701297-02ehsucnnm2oqe9bakr45eggb2hklooh.apps.googleusercontent.com',
	GOOGLE_CLIENT_ID: '130226701297-02ehsucnnm2oqe9bakr45eggb2hklooh.apps.googleusercontent.com',
	REACT_APP_APPLE_ID: '123',
	REACT_APP_FACEBOOK_APP_ID: '1452642712003466',
	REACT_APP_FACEBOOK_APP_SECRET: '8ed6aaeca75e8163fcb37f0e1a5c0276',
	REACT_APP_INSTAGRAM_APP_ID: '1076849570068113',
	REACT_APP_INSTAGRAM_APP_SECRET: '23fd4620060352e9c22a2ce7b14882fc',
	REACT_APP_INSTAGRAM_REDIRECT_URL: 'https://9294-181-87-123-202.ngrok-free.app',
	PREFIX: 'mievent',
	FETCH_TIMEOUT: 10000,
	API_URL: 'https://mievent.info/api',
	defgroup,
	vh2px,
	vw2px
}