import React, { useRef, useCallback, useState, useEffect, useContext } from "react";
import styled, { keyframes, css } from 'styled-components';
import { SEO } from '../../seo.js';
import { FaWaze, FaHome, FaArrowLeft, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FaLocationDot, FaTrash, FaEdit, FaCopy } from "react-icons/fa6";
import { GiHamburgerMenu } from "react-icons/gi";
//import { Button } from 'react-bootstrap';
//import Carousel from 'react-bootstrap/Carousel';            
import CountDown from 'count-down-react'
import { AddToCalendarButton, atcb_action } from 'add-to-calendar-button-react';
import { useHistory } from "react-router-dom";
//import img_maps from '../../assets/maps.png';
//import img_wtf from '../../assets/wtf.png';
//import img_rojo from '../../assets/fondo_rojo.jpg';
//import img_verde from '../../assets/fondo_verde.jpg';
//import img_xv from '../../assets/icon_xv.png';
//import img_xv from '../../assets/head_belu.png';
import img_xv from '../../assets/head_kiara2.png';
//import fondo_janos from '../../assets/janos-madero1.jpeg';
//import fondo_janos from '../../assets/janos_san_isidro_blue.jpg'; 
//import fondo_janos from '../../assets/janos_san_isidro_red.jpg'; 
import fondo_janos from '../../assets/janos_san_Isidro.jpg'; 
//import img_esquina from '../../assets/img_esquina.png';
//import img_corner_up_right from '../../assets/corner_up_right.png';
//import img_corner_bottom_left from '../../assets/corner_bottom_left.png';
import img_nudo from '../../assets/img_nudo.png';
import img_button from '../../assets/img_button2.png';
import fondo_mievento from '../../assets/fondo_mievento.jpg';
//import loading3 from '../../assets/loading3.png';
import loading4 from '../../assets/loading4.png';
/*import icon_signature from '../../assets/icon_signature.png';
import icon_fingerprint from '../../assets/icon_fingerprint.png';
import icon_check from '../../assets/icon_check.png';
import icon_diet from '../../assets/icon_diet.png';*/
import icon_meat from '../../assets/icon_meat.png';
import icon_veggie from '../../assets/icon_veggie.png';
import icon_vegan from '../../assets/icon_vegan.png';
import icon_celiac from '../../assets/icon_celiac.png';
import icon_gift from '../../assets/icon_gift2.png';
//import pic_belu from '../../assets/event_pic_belu.jpg';
/*import icon_dress_code from '../../assets/icon_dress_code.png';
import icon_map from '../../assets/icon_map2.png';
import icon_home from '../../assets/icon_home.png';
import icon_food from '../../assets/icon_food.png';
import icon_food2 from '../../assets/icon_food2.png';
import icon_options from '../../assets/icon_options.png';*/
import { SocketContext } from "../../socket-context.js";
import Cookies from "js-cookie";
import { config } from '../../config.js' // component display user (see detail on /example directory)
import dayjs from 'dayjs';
import _ from 'lodash';
/*import { InstagramLogin } from '@amraneze/react-instagram-login';
import AppleSignin from 'react-apple-signin-auth';*/
//import { GoogleLogin, useGoogleLogin, googleLogout } from "@react-oauth/google"
import { Fireworks } from '@fireworks-js/react'
//import locale from '../../es-us.js';
//import SignaturePad from 'react-signature-pad-wrapper'

import {
    LoginSocialGoogle,
    LoginSocialApple,
    LoginSocialAmazon,
    LoginSocialFacebook,
    LoginSocialGithub,
    LoginSocialInstagram,
    LoginSocialLinkedin,
    LoginSocialMicrosoft,
    LoginSocialPinterest,
    LoginSocialTwitter,
    IResolveParams,
  } from 'reactjs-social-login';
  
  import {
    GoogleLoginButton,
    AppleLoginButton,
    FacebookLoginButton,
    GithubLoginButton,
    AmazonLoginButton,
    InstagramLoginButton,
    LinkedInLoginButton,
    MicrosoftLoginButton,
    TwitterLoginButton,
  } from 'react-social-login-buttons';

let default_event = {
    url: '',
    res: {
        imgs: [],
        vids: [],
        music: []
    },    
    gift: {
        enabled: true,
        alias: '',
        cvu: '',
        cbu: '',
    },    
    options: [
        //{ text: 'Quiero bailar el vals!', key: 'vals' },
        //{ text: 'Quiero entrar al pogo!', key: 'pogo' },
        //{ text: 'Quiero torta!', key: 'torta' },
    ],
    anfi: {
        name: ''
    },
    /*fecha: '1/6',
    dia: 'sábado',
    hora: '21:30',*/
    date_start: '2024-06-01T21:30:00-03:00',
    date_end: '2024-06-02T05:30:00-03:00',
    dresscode: {
        //ellas: 'Elegante, not blue',
        //ellos: 'Elegante, green',
        ellas: '',
        ellos: '',
        not: ''
    },
    event: {
        name: '',
        type: '',
    },
    place: {
        name: '',
        dire: '',
        latlng: {
            lat: -34.60959133726496,
            lng: -58.363879376880895
        }
    },    
    alta: 0,
    baja: 0,
    form: { loaded: false, dia: '', fecha: '', hora: '' },
    //loginOptions: ["google", "instagram", "apple", "microsoft", "facebook"],
    loginOptions: { google: true, instagram: false, apple: false, microsoft: false, facebook: false },
    program: {},    
    cfg: { countdown: true, sign: true, gift: true },
}

//  import { calculateNewValue } from "@testing-library/user-event/dist/utils/index.js";
  
/*const FormScreen_OLD = () => {
    return (
        <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', 
                        justifyContent: 'center', alignItems: 'center', height: '100vh'  }}>
            <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', 
                            justifyContent: 'center', alignItems: 'center', height: '100vh'  }}>
                <img src="./public/logo512.png" 
                    style={{ height: '20%', borderRadius: "5%", marginTop: '10px' }}
                    alt='123'
                />
                <Button
                    variant='outline-primary'
                    style={{ textTransform: 'none', marginTop: '20px', fontWeight: 'bold', minWidth: '200px' }}
                    onClick={() => {
                        window.location.href = '/call';
                    }}
                >
                    Enviar
                </Button>
            </div>
            <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', 
                            justifyContent: 'center', alignItems: 'center', height: '100vh'  }}>
                <img src="./public/logo512.png" 
                    style={{ height: '20%', borderRadius: "5%", marginTop: '10px' }} 
                    alt='123'
                />
                <Button
                    variant='outline-primary'
                    style={{ textTransform: 'none', marginTop: '20px', fontWeight: 'bold', minWidth: '200px' }}
                    onClick={() => {
                        window.location.href = '/call';
                    }}
                >
                    Enviar
                </Button>
            </div>
        </div>
    );
};*/
//export default FormScreen_OLD;

const REDIRECT_URI = window.location.href;
const REDIRECT_IG = 'https://mievent.info/'; 
//const EVENT_URL = 'https://mievent.info/xv_de_belu';
const EVENT_URL = 'https://mievent.info/xv_de_kiara';
const weekdays = ["Domingo", "Lunes", "MArtes", "Miercoles", "Jueves", "Viernes", "Sábado"];
const monthnames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
//const titcol = '#DCAFFF';
//let maincol = '#0069FF';
let maincol = '#cecece';
let seccol = '#0CB2FF';
let titcol = maincol;
let hicol = '#eee';
let locol = '#888';
let theme = { maincol, seccol, locol, hicol };

let event_url = window.location.href;
let url = new URL(event_url);
if (event_url.includes('localhost')) event_url = 'https://mievent.info' + url.pathname; //EVENT_URL;
if (event_url.includes('ngrok')) event_url = 'https://mievent.info' + url.pathname; //EVENT_URL;
//googleLogout();

//dayjs.locale(locale, null, true);
//dayjs.locale('es-us');
console.log('REDIRECT_URI: ' + REDIRECT_URI);

const View = () => <Slideshow items={''} />;

const Slideshow = (props) => {
    /*const [{ items, activeIndex }, setIndex] = useState({
        items: props.items,
        activeIndex: 0, // begin with the first item
    });*/
    const [ nextProg, setNextProg ] = useState('main');
    const [ prog, setProg ] = useState('main');
    const [ goPath, setPath ] = useState('');
    const [ dieta, setDieta ] = useState('De todo!');
    const [ dietaDetail, setDietaDetail ] = useState('');
    const [ voy, setVoy ] = useState(false);
    const [ reason, setReason ] = useState('');
    const [ options, setOptions ] = useState({});
    const [ SideBarOpen, setSideBarOpen ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ signaturePad, setSignaturePad ] = useState();
    const [ eventData, setEventData ] = useState(default_event);
    const history = useHistory();

    //const [ animationFinished, setanimationFinished ] = useState(false);
    //const [ showAnimationStartLabel, setshowAnimationStartLabel ] = useState(false);
    //const [ nextIndex, setNextIndex ] = useState(1);
    //const [ moveIn, setMoveIn ] = useState(true);
    //const [ moveOut, setMoveOut ] = useState(false);
    const [{ moveIn, moveOut, zoom }, setMove ] = useState({ moveIn: true, moveOut: false, zoom: 0 });
    const {
        name,
        user, 
        event,
        setEvent,
        setUser,
        profile, 
        setProfile,
        provider, 
        setProvider,
        //eventData,
        //setEventData,
        fetchCall
    } = useContext(SocketContext);
    
    const fireworksRef = useRef(null);
    const signatureRef = useRef(null);
    /*const signatureRef = React.useCallback((signaturePad) => {
        setSignaturePad(signaturePad);
    }, []);*/

    const fireworksToggle = () => {
        if (!fireworksRef.current) return
        if (fireworksRef.current.isRunning) {
            fireworksRef.current.stop()
        } else {
            fireworksRef.current.start()
        }
    }

    const fireworksStop = () => {
        if (!fireworksRef.current) return;
        if (fireworksRef.current.isRunning) {
            fireworksRef.current.stop()
        }
    }

    const fireworksStart = () => {
        if (!fireworksRef.current) return
        if (!fireworksRef.current.isRunning) {
            fireworksRef.current.start()
        }
    }

    const onAnimationStart = () => {
        console.log('onAnimationStart');        
        //setanimationFinished(false);
        //setshowAnimationStartLabel(true);        
    };
    
    const onAnimationEnd = () => {
        console.log('onAnimationEnd');
        //setanimationFinished(true);
        //setshowAnimationStartLabel(false);        
        if (moveIn) {
            setMove({ moveIn: false, moveOut: false, zoom: 1 });
        } else {
            //setIndex((s) => ({ ...s, activeIndex: nextIndex }));
            setProg(nextProg);
            setMove({ moveIn: true, moveOut: false, zoom: 1 });
        }
    };

    useEffect(() => { 
        //alert( 'event_URL: ', event_url + ' provider: ' + provider);
        console.log( 'event_URL: ' + event_url + ' provider: ' + provider );
        //console.log('Do something after counter has changed', counter);
        if (!profile?.name) {
            let prf = Cookies.get('profile');
            if (prf?.name) {
                //setProfile(profile);
                setProfile(prf);
            }
        }	    
        if (!eventData?.form?.loaded) {
            if (event) {
                event_url = event;
            }
            let url = new URL(event_url);
            if (event_url.includes('localhost')) event_url = 'https://mievent.info' + url.pathname; //EVENT_URL;
            if (event_url.includes('ngrok')) event_url = 'https://mievent.info' + url.pathname; //EVENT_URL;            
            fetchCall({ data: { url: event_url }, endpoint: '/getEvent', tomsg: '5463', method: 'post', showmsg: 'Obteniendo evento..', 
                onok: async responseData => {
                    setLoading(false);
                    //console.log('/getEvent ' + JSON.stringify(responseData));                                
                    if (responseData.success) {                                    
                        let dianame         = weekdays[dayjs(responseData.event.date_start).day()];
                        let fechadia        = dayjs(responseData.event.date_start).format('D');
                        let fechames        = dayjs(responseData.event.date_start).format('M');
                        let fechamesname    = monthnames[dayjs(responseData.event.date_start).month()];
                        //let fechamesname  = monthnames[dayjs(responseData.event.date_start).format('MM')];
                        let horastart       = dayjs(responseData.event.date_start).format('H:mm');            
                        let horaend         = dayjs(responseData.event.date_end).format('H:mm');            
                        theme               = responseData.event.res.theme;
                        titcol              = theme.maincol;
                        seccol              = theme.seccol;
                        responseData.event.form = { loaded: true, dianame, fechadia, fechames, horastart, horaend, fechamesname };
                        //console.log('event.form ' + JSON.stringify(responseData.event.form));
            //responseData.event?.program.voy.push('signature');
            /*const program = [
                ...responseData.event?.program.voy.slice(0, 1),
                'signature',
                ...responseData.event?.program.voy.slice(1)
            ];            
            responseData.event?.program.voy = program;*/
            //responseData.event?.program.voy.splice(1, 0, 'signature');
            console.log(JSON.stringify(responseData.event?.program.voy));
                        setPath(_.keys(responseData.event?.program)[0]);
                        setEventData(responseData.event);
                        //Alert.alert( 'Atención: ', 'Confirmación enviada!', [{ text: 'Aceptar', onPress: () => { }}]);														                                        
                    } else {
                        //Alert.alert( 'Error: ', 'Usuario no encontrado. ', [{ text: 'Aceptar', onPress: () => {} }]);														                    
                        alert('Evento no encontrado ' + event_url);
                    }                                        
                }, 
                onerr: err => {
                    alert('Error cargando evento ' + event_url + ' ' + err);
                },
                usews: false
            });
            setMove({ moveIn: true, moveOut: false, zoom: 0 });
        }
    }, []);
    
    const getProfile = (data) =>{
        //setLoading(false);
        console.log('PROVIDER: ' + JSON.stringify(provider));
        console.log('DATA: ' + JSON.stringify(profile));
        Cookies.set('profile', profile);                                          
        /*fetchCall({ data: { token: data.credential }, endpoint: '/verifyToken', tomsg: '5467', method: 'post', showmsg: 'Verificando..', 
            onok: async responseData => {
                console.log('/verifyToken ' + JSON.stringify(responseData));                                                                    
                if (responseData.success) {                                    
                } else {
                    //Alert.alert( 'Error: ', 'Usuario no encontrado. ', [{ text: 'Aceptar', onPress: () => {} }]);														                    
                }                                        
            }, 
            onerr: err => {},
            usews: false
        });*/                                                                                                            
        setLoading(true);
        fetchCall({ data: { email: data.email, name: data.given_name, lastName: data.family_name, profile }, endpoint: '/getUser', tomsg: '5472', method: 'post', showmsg: 'Buscando..', 
            onok: async responseData => {
                setLoading(false);
                console.log('/getUser ' + JSON.stringify(responseData));                                                                    
                if (responseData.success) {                                    
                    setUser(responseData.user);
                } else {
                    //Alert.alert( 'Error: ', 'Usuario no encontrado. ', [{ text: 'Aceptar', onPress: () => {} }]);														                    
                }                                        
            }, 
            onerr: err => {
                setLoading(false);
            },
            usews: false
        });
    }
    
    const _sendConfirm = (prog) => {
        if (!provider) {
            alert('Debes identificarte primero!')
            return;
        }
        let event = {
            url: 			eventData.url,
            date:           eventData.date_start,
            name:           eventData.event.name,
            date_confirm:	dayjs().toISOString(),
            voy: 			voy,
            reason: 		reason,
            menu: 			dieta + ' ' + dietaDetail,            
            options:		options,			
            provider,
        }
        let prof = _.pick(profile, ['name', 'given_name', 'family_name', 'piture']);
        setLoading(true);
        fetchCall({ data: { email: profile.email, profile: prof, event }, endpoint: '/modUser', tomsg: '5465', method: 'post', showmsg: 'Confirmando..', 
            onok: async responseData => {
                console.log('/modUser ' + JSON.stringify(responseData));                                                
                setLoading(false);
                if (responseData.success) {
                    //Alert.alert( 'Atención: ', 'Confirmación enviada!', [{ text: 'Aceptar', onPress: () => { }}]);														                                        
                    if (prog) goProg(prog); else goNext();
                } else {
                    //Alert.alert( 'Error: ', 'Usuario no encontrado. ', [{ text: 'Aceptar', onPress: () => {} }]);														                    
                    alert( 'Error: Usuario no encontrado');
                    if (prog) goProg(prog); else goNext(); 
                }                                        
            }, 
            onerr: err => {
                setLoading(false);
            },
            usews: false
        });
    }
    
	const onLoginStart = useCallback(() => {
		//alert('login start ' + REDIRECT_URI);
        /*window.google.accounts.id.prompt(notification => {
            if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
              // clear g_state cookie if the pop-up has already been closed
              document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
            }
        });*/
	}, []);

	const onLogoutSuccess = useCallback(() => {
		setProfile(null);
		setProvider('');
		//alert('logout success');
	}, []);

	/*const onLogout = useCallback(() => {}, []);

    const loginToGoogle = useGoogleLogin({
        onSuccess: tokenResponse => {
            //localStorage.setItem("loginWith", "Google")
            //localStorage.setItem("accessToken", tokenResponse.access_token)
            console.log('loginToGoogle: ' + JSON.stringify(tokenResponse));
            let token = tokenResponse.access_token;

            fetchCall({ data: { token }, endpoint: '/verifyToken', tomsg: '5467', method: 'post', showmsg: 'Verificando..', 
                onok: async responseData => {
                    console.log('/verifyToken ' + JSON.stringify(responseData));                                                                    
                    if (responseData.success) {                                    

                    } else {
                        //Alert.alert( 'Error: ', 'Usuario no encontrado. ', [{ text: 'Aceptar', onPress: () => {} }]);														                    
                    }                                        
                }, 
                onerr: err => {},
                usews: false
            });

        },
    })*/      

    const goHome = () => {
        setMove({ moveIn: false, moveOut: true, zoom: 0 });
        setPath('voy'); 
        setNextProg(eventData?.program['voy'][0]);
    };

    const goProg = (prg) => {
        let pointer = _.findIndex(eventData?.program[goPath], panel => panel === prg);
        if ((pointer > -1) && (eventData?.program[goPath][pointer] !== 'end')) {
            setMove({ moveIn: false, moveOut: true, zoom: 0 });
            setNextProg(eventData?.program[goPath][pointer]);
        }
    };

    const goNext = () => {
        let pointer = _.findIndex(eventData?.program[goPath], panel => panel === prog) + 1;
        if (eventData?.program[goPath][pointer] === 'options' && eventData?.options.length === 0) pointer++;
        //if (eventData?.program[goPath][pointer] === 'login') pointer++; //SACAR
        if (eventData?.program[goPath][pointer] === 'login') {
            if (profile.email) pointer++; 
        }
        if (pointer && eventData?.program[goPath][pointer] !== 'end') {
            setMove({ moveIn: false, moveOut: true, zoom: 0 });
            setNextProg(eventData?.program[goPath][pointer]);
        }
        console.log('goNext: ' + pointer + ' ' + eventData?.program[goPath][pointer]);
    };

    const goBack = () => {
        let pointer = _.findIndex(eventData?.program[goPath], panel => panel === prog) - 1;
        if (pointer < 0) {
            history.push('/');
        } else {
            if (eventData?.program[goPath][pointer] === 'options' && eventData?.options.length === 0) pointer--;
            if (eventData?.program[goPath][pointer] === 'login') {
                if (profile.email) pointer--; 
            }
            if (eventData?.program[goPath][pointer] === 'end') {
                goProg('confirm');
                return;
            }
            if (pointer > -1) {
                setPath('voy');
                setMove({ moveIn: false, moveOut: true, zoom: 0 });
                setNextProg(eventData?.program[goPath][pointer]);
            } else {
                //window.location.href = '/';            
                history.push('/');
            }
        }
    };

    const responseInstagram = (response) => {
        console.log(response);
    };

    /*const program = { voy: [
        'main',
        'login',
        'confirm',
        'quebueno',
        'dieta',
        'options',
        'gift',
        'resumen_voy',
        'te_espero',
        'end',
        'novoy',
        'end'
    ], novoy: [
        'novoy',
        'end'
    ]};*/

    const panels = { 
        main: 
        <Panel key='main' className="w3-container w3-green w3-cell" >
            <PanelLine $fs='4vh' $fn={'Bebas Neue'} $color={titcol}><p>{eventData?.form.dianame} {eventData?.form.fechadia} de {eventData?.form.fechamesname}</p></PanelLine>            
            <PanelLine $fs='3.5vh' $fn={'Bebas Neue'} $color={titcol}><p>de {eventData?.form.horastart} a {eventData?.form.horaend}</p></PanelLine>
            <div style={{ background: titcol, height: '2px', width: '40vw', marginTop: '10px', marginBottom: '10px' }}></div>
            <br/>
            {/*<PanelLine><p className='w3-shortstack'>cumplo mis XV y estas invitado!</p></PanelLine>            
            <PanelLine><p style={{ fontFamily: 'Lobster'}}>cumplo mis XV y estas invitado!</p></PanelLine>
            <PanelLine><p className='w3-lobsterShadow'>cumplo mis XV y estas invitado!</p></PanelLine>*/}
            <br/>            
            <img src={img_nudo} style={{ width: '200px', marginBottom: '20px' }} alt='523'/>                                    
            <br/>
            <PanelLine $fs='3.5vh' $fn={'Bebas Neue'} $color={titcol}><p>Confirmá tu asistencia!</p></PanelLine>        
            {/*<NextButton style={{ marginTop: '30px'}} onClick={() => { goNext() }}>
                Siguiente
            </NextButton>*/}            
            <br/>
            <Abutton onClick={() => { goNext() }}>
                Siguiente
            </Abutton>
        </Panel>,
        login: 
        <Panel key='login' className="w3-container w3-blue w3-cell">
            <div className='itm'>
                <PanelLine $fs='3.5vh' $color={titcol} style={{ marginBottom: '20px' }}><p>Identificate</p></PanelLine>                    
                { eventData?.loginOptions['google'] && 
                    <LoginSocialGoogle
                        //client_id={process.env.REACT_APP_GG_APP_ID || ''}
                        client_id={config.REACT_APP_GG_APP_ID || ''}
                        onLoginStart={onLoginStart}
                        redirect_uri={REDIRECT_URI}
                        //typeResponse="idToken"
                        //typeResponse="accessToken"
                        //isOnlyGetToken 
                        scope="openid profile email"
                        discoveryDocs="claims_supported"
                        //access_type="offline"
                        ux_mode="popup"                        
                        onResolve={({ provider, data }) => {
                    		//alert('login resolve ' + REDIRECT_URI);
                            setLoading(false);
                            setProvider(provider);
                            getProfile(data);
                            console.log('PROVIDER: ' + JSON.stringify(provider));
                            console.log('DATA: ' + JSON.stringify(data));
                            setProfile(data);  
                            Cookies.set('profile', data);                                          
                            goNext();
                            /*fetchCall({ data: { token: data.credential }, endpoint: '/verifyToken', tomsg: '5467', method: 'post', showmsg: 'Verificando..', 
                                onok: async responseData => {
                                    console.log('/verifyToken ' + JSON.stringify(responseData));                                                                    
                                    if (responseData.success) {                                    
                                    } else {
                                        //Alert.alert( 'Error: ', 'Usuario no encontrado. ', [{ text: 'Aceptar', onPress: () => {} }]);														                    
                                    }                                        
                                }, 
                                onerr: err => {},
                                usews: false
                            });*/             
                        }}
                        onReject={err => {
                    		alert('login reject ' + REDIRECT_URI);
                            setLoading(false);
                            console.log(err);
                        }}
                    >
                        <GoogleLoginButton className='btn-social' onClick={() => {
                            setLoading(true);
                        }}>
                            <span>con Google</span>
                        </GoogleLoginButton>                        
                    </LoginSocialGoogle>
                }                
                {/*<GoogleLoginButton onClick={() => loginToGoogle()}>
                    <span>con Google2</span>
                </GoogleLoginButton>*/}
                <br />
                { eventData?.loginOptions['instagram'] && 
                    <LoginSocialInstagram
                        client_id={config.REACT_APP_INSTAGRAM_APP_ID || ''}
                        client_secret={config.REACT_APP_INSTAGRAM_APP_SECRET || ''}
                        //redirect_uri={REDIRECT_IG}
                        //redirect_uri={REDIRECT_URI}                        
                        //redirect_uri={config.REACT_APP_INSTAGRAM_REDIRECT_URL}
                        //redirect_uri={'https://mievent.backloop.dev/xv_de_belu'}
                        redirect_uri={'https://mievent.info/xv_de_belu'}
                        onLoginStart={onLoginStart}
                        onLogoutSuccess={onLogoutSuccess}
                        onResolve={({ provider, data }) => {
                            setLoading(false);
                            setProvider(provider);
                            setProfile(data);
                            goNext();
                        }}
                        onReject={(err) => {
                            setLoading(false);
                            console.log(err);
                        }}
                    >
                        <InstagramLoginButton className='btn-social' onClick={() => {
                            setLoading(true);
                        }}>
                            <span>con Instagram</span>
                        </InstagramLoginButton>
                    </LoginSocialInstagram>
                }
                { eventData?.loginOptions['apple'] &&                 
                    <LoginSocialApple
                        client_id={config.REACT_APP_APPLE_ID || ''}
                        scope={'name email'}
                        redirect_uri={REDIRECT_URI}
                        onLoginStart={onLoginStart}
                        onResolve={({ provider, data }) => {
                            setLoading(false);
                            setProvider(provider);
                            setProfile(data);
                        }}
                        onReject={err => {
                            setLoading(false);
                            console.log(err);
                        }}
                    >
                        <AppleLoginButton className='btn-social' onClick={() => {
                            setLoading(true);
                        }}>
                            <span>con Apple</span>
                        </AppleLoginButton>
                    </LoginSocialApple>
                }
                <Loading2 src={loading4} alt='Loading...' $move={loading}/>                
                <br />
                {/*<Abutton className='btn-social' onClick={() => { goNext() }}>
                    Siguiente
                </Abutton>*/}            
            </div>
        </Panel>,
        confirm:
        <Panel key='confirm'>
            <div className='itm'>
                <br />
                <br />
                <PanelLine $fs={'6vh'} $color={titcol}><p>{profile.given_name}</p></PanelLine>        
                <br />
                <PanelLine $fs={'4vh'} $color={titcol}><p>Confirmá tu</p></PanelLine>        
                <br />
                <PanelLine $fs={'4vh'} $color={titcol}><p>asistencia!</p></PanelLine>        
                <br />
                <Abutton onClick={() => {
                    fireworksStart();
                    setVoy(true);
                    goNext();
                }}>
                    Voy a ir!
                </Abutton>            
                <br />
                <Abutton onClick={() => {
                            //setPath('novoy');
                            setVoy(false);
                            goProg('novoy');
                        }}
                >
                    No puedo ir!
                </Abutton>            
            </div>
        </Panel>,
        quebueno:
        <Panel key='quebueno'>
            <PanelLine  $fs={'8vw'} $color={titcol}><p>Excelente!</p></PanelLine>        
            <br />
            <br />
            <br />
            <Abutton onClick={() => { 
                fireworksStop();
                goNext() 
            }}>
                Siguiente
            </Abutton>            
        </Panel>,
        dieta:
        <div key='dieta' style={{ display: 'flex', flexDirection: 'column', 
                justifyContent: 'center', alignItems: 'space-between',
                width: '100vw', height: '100vh', color: '#eee', fontFamily: 'Sofia', fontSize: '3vw', textAlign: 'center'}}
        >
                <div style={{}}>
                    <br/>
                    <PanelLine style={{ marginTop: '10px', marginBottom: '0px' }} $fs={'4vh'} $color={titcol}><p>Cuál es tu dieta:</p></PanelLine>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-end', 
                                    width: '100%', paddingTop: '1vh'}}>
                        <div style={{ marginRight: '0.5vh', width: '30%' }}>
                            <img src={icon_vegan} style={{ width: '10vh', margin: 'auto', opacity: dieta === 'Vegana' ? 1: 0.3 }} alt=''
                                onClick={ev => { setDieta('Vegana') }}
                            />
                            <label className='labelMenu'>Vegana</label>
                        </div>
                        <div style={{ marginLeft: '0.5vh', width: '30%' }}>
                            <img src={icon_veggie} style={{ width: '10vh', margin: 'auto', opacity: dieta === 'Vegetariana' ? 1: 0.3 }} alt=''
                                onClick={ev => { setDieta('Vegetariana') }}
                            />
                            <label className='labelMenu'>Vegetariana</label>
                        </div>
                    </div>
                </div>
                <div style={{ justifiContent: 'flex-start', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',
                                    width: '100%', paddingTop: '3vh'}}>
                        <div style={{ marginRight: '0.5vh', width: '30%' }}>                
                            <img src={icon_celiac} style={{ width: '10vh', margin: 'auto', opacity: dieta === 'Celíaca' ? 1: 0.3 }} alt=''
                                onClick={ev => { setDieta('Celíaca') }}
                            />
                            <label className='labelMenu'>Celíaca</label>
                        </div>
                        <div style={{ marginLeft: '0.5vh', width: '30%' }}>
                            <img src={icon_meat} style={{ width: '10vh', margin: 'auto', opacity: dieta === 'De todo!' ? 1: 0.3 }} alt=''
                                onClick={ev => { setDieta('De todo!') }}
                            />
                            <label className='labelMenu'>De todo!</label>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',
                                    width: '100%', paddingTop: '3vh'}}>
                    <input className="w3-input w3-round-large w3-hover-blue" type='text' value={dietaDetail}
                            style={{ width: '70%', fontSize: '24px' }}
                            placeholder='Acotaciones...' 
                            onChange={e => { setDietaDetail(e.target.value); }}
                    />
                </div>
                <Abutton style={{ marginTop: '20px' }}
                    //x={{position: 'absolute', left: '35vw', right: '35vw', bottom: '25vh'}}
                    onClick={() => { goNext() }}
                >
                    Siguiente
                </Abutton>            
        </div>,
        options:
        <Panel key='options' className="w3-container w3-blue w3-cell">
            <div className='itm'>
                { eventData?.options.map( opt => { 
                    return(
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '70vw' }} key={opt.key}>
                            <PanelLine $color={titcol}><p>{opt.text}</p></PanelLine>        
                            <input className="w3-check" type="checkbox" 
                                    checked={options[opt.key]===true} 
                                    onChange={ev => { 
                                            let option = {...options};
                                            option[opt.key] = !option[opt.key];
                                            setOptions(option) 
                                    }} 
                            />
                        </div>
                    )
                })}
                <Abutton onClick={() => { goNext() }} >
                    Siguiente
                </Abutton>            
            </div>
        </Panel>,
        gift:
        <Panel key='gift' className="w3-container w3-blue w3-cell">
            <div className='itm'>
                <img src={icon_gift} alt='gift' style={{ width: '15vh', tintColor: '#DCAFFF' }} />
                <PanelLine $fs={'3vh'} $color={titcol}><p>Lo importante</p></PanelLine>        
                <PanelLine $fs={'3vh'} $color={titcol}><p>es que vengas!</p></PanelLine>        
                <PanelLine $fs={'3vh'} $color={titcol}><p>Pero por si acaso...</p></PanelLine>        
                <div style={{ display: 'flex', flexDirection: 'column' }}
                    onClick={() => { 
                        navigator.clipboard.writeText(eventData?.gift.alias).then(() => {
                            /*setBttnText("COPIED");
                            setTimeout(function () {
                                setBttnText("COPY CODE");
                            }, 3000);*/
                        }).catch((err) => {
                            console.log(err.message);   
                        });                    
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <PanelLine $fs={'4vh'} $fn={'Bebas Neue'} $color={titcol} style={{ marginTop: '2vh', marginBottom: '2vh' }} ><p>Alias</p></PanelLine>        
                        <FaCopy style={{ marginRight: '2vh', marginBottom: '1vh', width: '4vh', height: '4vh', color: '#eee',  }}/>
                    </div>
                    <PanelLine $fs={'4vh'} $fn={'Bebas Neue'} $color={titcol} style={{ marginTop: '0vh', marginBottom: '2vh' }} ><p>{eventData?.gift.alias}</p></PanelLine>        
                </div>
                <Abutton onClick={() => { goNext() }}>
                    Siguiente
                </Abutton>            
            </div>
        </Panel>,   
        signature: 
        <Panel key='signature'>
            <div className='itm div-signature' style={{ backgroundColor: '#444' }}>
                <div style={{ display: 'flex', position: 'absolute', top: '30vh', left: '20vw', width: '58vw', height: '50vh',
                        borderWidth: '2px', borderColor: '#282', borderStyle: 'solid', backgroundColor: '#444' }} 
                >
                    {/*<SignaturePad width={config.vw2px * 60} height={config.vh2px * 40} redrawOnResize 
                        ref={signatureRef}
                        //ref={ref => signatureRef.current = ref} 
                        //style={{ borderWidth: '2px', borderColor: '#bb2', borderStyle: 'solid' }}
                        options={{ minWidth: 5, maxWidth: 10, penColor: 'rgb(66, 133, 244)'}} 
                    />*/}

                    <div style={{ display: 'flex', position: 'absolute', flexDirection: 'row', flexJustify: 'center',
                                    top: '1vh', left: '0vw', width: '60vw', height: '5vh' }}>
                        <FaTrash style={{ marginLeft: '10px', width: '20px', height: '20px', color: '#e55' }}/>
                        <div style={{ marginLeft: '10px', width: '20px', height: '20px', borderRadius: '50%', backgroundColor: '#fff' }}
                            onClick={() => signatureRef?.current?.instance?.penColor('#fff')}
                        /> 
                        <div style={{ marginLeft: '10px', width: '20px', height: '20px', borderRadius: '50%', backgroundColor: '#000' }}
                            onClick={() => signatureRef?.current?.instance?.penColor('#000')}
                        />
                        <div style={{ marginLeft: '10px', width: '20px', height: '20px', borderRadius: '50%', backgroundColor: '#f00' }}
                            onClick={() => {
                                    //console.log('ONCLICK ' + JSON.stringify(signatureRef?.current?.instance));
                                    console.log('ONCLICK ' + _.keys(signatureRef.current));
                                    //console.log('ONCLICK ' + signatureRef.current.signaturePad.penColor);
                                    signatureRef?.current?.penColor('#f00')
                                }
                            } 
                        />
                        <div style={{ marginLeft: '10px', width: '20px', height: '20px', borderRadius: '50%', backgroundColor: '#00f' }}
                            onClick={() => signatureRef?.current?.instance?.penColor('#00f')}
                        />
                        <div style={{ marginLeft: '10px', width: '20px', height: '20px', borderRadius: '50%', backgroundColor: '#0f0' }}
                            onClick={() => signatureRef?.current?.instance?.penColor('#0f0')}
                        />
                    </div>
                </div>        
                {/*<SignaturePad 
                    //width={'500px'} height={'500px'} 
                    width={500} 
                    height={500} 
                    redrawOnResize 
                    backgroundColor='#333'
                    //minWidth='500px'
                    //maxWidth='500px'
                    //minHeight='500px'
                    //maxHeight='500px'
                    //dotSize='3px'
                        //style={{ borderWidth: '2px', borderColor: '#bb2', borderStyle: 'solid' }}
                        options={{ minWidth: 5, maxWidth: 10, penColor: 'rgb(66, 133, 244)'}} 
                />*/}
            </div>            
        </Panel>,   
        
        resumen_voy:       
        <Panel key='resumen_voy' className="w3-container w3-blue w3-cell">
            <div className='itm'>
                <PanelLine $fs={'3.5vh'} $color={theme.locol} style={{ marginTop: '0vh', marginBottom: '1vh' }}><p>Soy:</p></PanelLine>        
                <PanelLine $fs={'3vh'} $color={theme.hicol} $fn={'Bebas Neue'}><p>{profile.given_name}</p></PanelLine>        
                <PanelLine $fs={'3.5vh'} $color={theme.locol} style={{ marginTop: '1vh', marginBottom: '1vh' }}><p>Voy a asistir:</p></PanelLine>        
                <PanelLine $fs={'3vh'} $color={theme.hicol} $fn={'Bebas Neue'}><p>Si!</p></PanelLine>        
                <PanelLine $fs={'3.5vh'} $color={theme.locol} style={{ marginTop: '1vh', marginBottom: '1vh' }}><p>Dieta:</p></PanelLine>        
                <PanelLine $fs={'3vh'} $color={theme.hicol} $fn={'Bebas Neue'}><p style={{ width: '80vw', maxWidth: '80vw', textAlign: 'center', textWrap: 'wrap' }}>{dieta} {dietaDetail}</p></PanelLine>        
                { eventData?.options.map( opt => {                    
                    return options[opt.key] ? <PanelLine $fs={'3vh'} key={opt.key}><p>{opt.text}</p></PanelLine>: ''
                })}  
                <br/>
                <Loading2 src={loading4} alt='Loading...' $move={loading}/>                
                <br/>
                <Abutton onClick={e => {
                    let str = profile.given_name;                    
                    str = str + ' va a ir';
                    str = str + '\nDieta: ' + dieta;
                    _.each(eventData?.options, opt => {
                        if (options[opt.key]) str = str + '\n' + opt.text;
                    })
                    //if (vals) str = str + '\nQuiere bailar el vals';
                    console.log(str);    
                    _sendConfirm();
                }}>
                    Enviar!
                </Abutton>            
            </div>
        </Panel>,
        te_espero:
        <Panel key='te_espero' className="w3-container w3-blue w3-cell">
            <div className='itm'>
                <br />            
                <PanelLine $fs={'3vh'} $color={titcol}><p>Te espero el</p></PanelLine>        
                <PanelLine $fs={'3vh'} $color={titcol}><p>{eventData?.form.fechadia} de {eventData?.form.fechamesname} {eventData?.form.horastart} en</p></PanelLine>        
                <br />
                <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '0vh', marginBottom: '0vh',  
                        justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{ display: 'flex', width: '80px' }} />
                    <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '0vh', marginBottom: '0vh',  
                            justifyContent: 'center', alignItems: 'center', background: ''}}>
                        <PanelLine $fs={'3vh'} $fn={'Bebas Neue'} style={{ marginTop: '1vh', marginBottom: '1vh' }} $color={titcol}><p>{eventData?.place?.name}</p></PanelLine>         
                        <PanelLine $fs={'3vh'} $fn={'Bebas Neue'} $color={theme.locol}><p>{eventData?.place.dire}</p></PanelLine>  
                    </div>      
                    <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: '0vh', marginBottom: '0vh',  
                        justifyContent: 'center', alignItems: 'center', background: ''}}>
                        <FaLocationDot size={30}  style={{ color: '#cc5555', marginLeft: '15px' }} 
                            //https://www.google.com/maps/search/?api=1&parameters
                            onClick={e => { 
                                let { lat, lng } = eventData?.place?.latlng;
                                if (lat && lng) window.open("https://maps.google.com?q="+ lat + ',' + lng );
                            }}
                        />
                        <FaWaze size={40}  style={{ color: '#7AADFF', marginLeft: '15px' }} 
                            //https://waze.com/ul?param_name=value[&param_name=value]
                            onClick={e => { 
                                let { lat, lng } = eventData?.place?.latlng;
                                if (lat && lng) window.open("https://www.waze.com/ul?ll=" + lat + "%2C" + lng + "&navigate=yes" );
                                //if (lat && lng) window.open("https://www.waze.com/ul?q=janos-san-isidro&navigate=yes" );
                                
                            }}
                        />                    
                    </div>
                </div>

                {(eventData?.dresscode?.todos || eventData?.dresscode?.ellas || eventData?.dresscode?.ellos || eventData?.dresscode?.not) &&  
                    <PanelLine $fs={'3vh'} style={{ marginTop: '2vh', marginBottom: '1vh' }} $color={titcol}><p>Dress code:</p></PanelLine>  
                }      
                {eventData?.dresscode?.ellas && <PanelLine $fs={'3vh'} $fn={'Bebas Neue'}$color={titcol}><p>Ellas: {eventData?.dresscode?.ellas}</p></PanelLine>}        
                {eventData?.dresscode?.ellos && <PanelLine $fs={'3vh'} $fn={'Bebas Neue'}$color={titcol}><p>Ellos: {eventData?.dresscode?.ellos}</p></PanelLine>}        
                {eventData?.dresscode?.todos && <PanelLine $fs={'3vh'} $fn={'Bebas Neue'}$color={titcol}><p>{eventData?.dresscode?.todos}</p></PanelLine>}        
                {eventData?.dresscode?.not && <PanelLine $fs={'3vh'} $fn={'Bebas Neue'}$color={titcol}><p>{eventData?.dresscode?.not}</p></PanelLine>}        
                <br />
                {/*<StyledAdd2Calendar>
                    <AddToCalendarButton
                        name="XV de Belu!"
                        options={['Apple','Google','Outlook.com','Yahoo']}
                        location={eventData?.place?.name}
                        //startDate="2024-06-01"
                        startDate={dayjs(eventData?.date_start).format('YYYY-MM-DD')}
                        //endDate="2024-06-02"
                        endDate={dayjs(eventData?.date_end).format('YYYY-MM-DD')}
                        startTime="21:30"
                        endTime="05:30"
                        //timeZone="America/Argentina/Buenos_Aires"
                        timeZone="currentBrowser"
                        //styleDark="--date-btn-background:#B056FF;--date-btn-text:#DCAFFF;--list-background:#B056FF"
                        styleDark="--font:Bebas Neue;--btn-font-weight:400;--font-size:3em; --btn-text:#DCAFFF;--btn-background:#00000040;--btn-border:#B056FF;--date-btn-text:#DCAFFF;--list-background:#B056FF"
                        listStyle='modal'
                        language='es'
                        lightMode='dark'
                        //buttonStyle="date"
                        //hideBackground={true}
                        //description="Check out the maybe easiest way to include Add to Calendar Buttons to your web projects:[br]→ [url]https://add-to-calendar-button.com/|Click here![/url]"
                        //label="Guardar recordatorio en calendar"
                        //iCalFileName="Reminder-Event"
                        //listStyle="overlay"
                        //size="5"
                        //lightMode="bodyScheme"
                    >
                    </AddToCalendarButton>
                </StyledAdd2Calendar>*/}
                 
                <Abutton $quiet={true} onClick={() => {  
                    let calendar_config = {
                        name: eventData?.name, //'XV de Belu!',
                        options: ['Apple','Google','Outlook.com','Yahoo'],
                        location: eventData?.place?.name,
                        startDate: dayjs(eventData?.date_start).format('YYYY-MM-DD'),
                        endDate: dayjs(eventData?.date_end).format('YYYY-MM-DD'),
                        startTime: '21:30',
                        endTime: '05:30',
                        timeZone: 'currentBrowser',
                        language: 'es',
                        //styleDark: "--font:Bebas Neue;--btn-font-weight:400;--font-size:3em; --btn-text:#DCAFFF;--btn-background:#00000040;--btn-border:#B056FF;--date-btn-text:#DCAFFF;--list-background:#B056FF",
                    }
                    const btn = document.getElementById('addCalendarButton');
                    atcb_action(calendar_config, btn);
                }}>
                    <div id='addCalendarButton'>Agregar a Calendario</div>
                </Abutton>            
                <br />
                <Abutton onClick={() => { 
                    //window.location.href = '/';            
                    history.push('/');
                }}>
                    Gracias!
                </Abutton>            
            </div>
        </Panel>,
        novoy:
        <Panel key='novoy' className="w3-container w3-blue w3-cell">
            <div className='itm'>
                <PanelLine $fs={'7vw'} $color={titcol}><p>Que lástima!</p></PanelLine>        
                <PanelLine $fs={'3vh'} $color={titcol}><p>Soy:</p></PanelLine>        
                <PanelLine $fs={'4vh'} $fn={'Bebas Neue'} $color={titcol}><p> {profile.given_name}</p></PanelLine>  
                <PanelLine $fs={'3vh'} $color={titcol}><p>y no puedo asistir</p></PanelLine>        
                <br />
                <input className="w3-input w3-round-large w3-hover-blue" type='text' value={reason}
                        placeholder='porque...' 
                        onChange={e => { setReason(e.target.value); }}
                />
                <br />
                <Abutton onClick={e => {
                    let str = profile.given_name + ' no va a ir';
                    if (reason) str = str + '\nRazones: ' + reason;
                    console.log(str);    
                    _sendConfirm();
                }}>
                    Enviar!
                </Abutton>            
            </div>
        </Panel>,
        gift_no_voy:
        <Panel key='gift' className="w3-container w3-blue w3-cell">
            <div className='itm'>
                <img src={icon_gift} alt='123'style={{ width: '12vh', tintColor: '#DCAFFF' }} />
                <PanelLine $fs={'3vh'} $color={titcol}><p>Lo importante</p></PanelLine>        
                <PanelLine $fs={'3vh'} $color={titcol}><p>es que vengas!</p></PanelLine>        
                <PanelLine $fs={'3vh'} $color={titcol}><p>Pero por si acaso...</p></PanelLine>        
                <br/>
                <PanelLine $fs={'3vh'} $color={titcol} style={{ marginTop: '2vh', marginBottom: '2vh' }}><p>Alias:</p></PanelLine>                        
                <PanelLine $fs={'4vh'} $fn={'Bebas Neue'} $color={titcol}><p>{eventData?.gift.alias}</p></PanelLine>        
                <Abutton onClick={() => { goNext() }}>
                    Siguiente
                </Abutton>            
            </div>
        </Panel>,   
        gracias_por_confirmar:
        <Panel key='gracias_por_confirmar' className="w3-container w3-blue w3-cell">
            <div className='itm'>
                <PanelLine $fs={'7vw'} $color={titcol}><p>Gracias</p></PanelLine>        
                <PanelLine $fs={'7vw'} $color={titcol}><p>por avisar!</p></PanelLine>        
                <br />
                <Abutton onClick={e => {
                    //window.location.href = '/';            
                    history.push('/');
                }}>
                    Inicio
                </Abutton>            
            </div>
        </Panel>,
    };

    if (!eventData?.form?.loaded) {
        return (
            <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', backgroundColor: '#000',
                justifyContent: 'flex-start', alignItems: 'center', height: '100vh'  }}>
                <SEO
                    title='Mievent.info'
                    description='Mievent.info'
                    name='Mievent.info'
                    type='article'
                />                            
                <img src={fondo_mievento}
                    style={{ width: '100vw' }} 
                    alt='123'
                />
                <h3 style={{ position: 'absolute', top: '60vh', fontSize: '7vw', fontWeight: 'bold', color: '#ccc' }}>
                    CARGANDO TU EVENTO...
                </h3>
                <Loading src={loading4} alt='Loading...' $move={true}/>                
            </div>
        )
    }

    return (
        <MainDiv>
            <SEO
                title='Mievent.info'
                description='Mievent.info'
                name='Mievent.info'
                type='article'
            />                            
            <ImgBackGround src={fondo_janos} />
            <Opacity />
            <Fireworks
                ref={fireworksRef}
                autostart={false}
                options={{ opacity: 0.5 }}
                style={{
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    position: 'fixed',
                    //background: '#000',
                    opacity: 0.8,
                    particles: 50,
                    friction: 0.96,
                    intensity: 20,
                    lineStyle: 'round',                    
                    lineWidth: {
                        explosion: {
                            min: 1,
                            max: 7
                        },
                        trace: {
                            min: 1,
                            max: 5
                        }
                    },                 
                    decay: {
                        min: 0.001,
                        max: 0.02
                    },                       
                    mouse: {
                        click: true,
                        move: false,
                        max: 5
                    }
                }}
            />            
            <img src={img_xv} style={{ position: 'absolute', top: '3vh', width: '250px', margin: 'auto' }} alt=''/>
            {/*<img src={img_corner_up_right} style={{ position: 'absolute', top: '0px', right: '0px', width: '150px' }} alt='223'/>                        
            <img src={img_corner_bottom_left} style={{ position: 'absolute', bottom: '0px', left: '0px', width: '150px' }} alt='223'/>*/}                        
            {/*const program = { voy: [
                'main',
                'login',
                'confirm',
                'quebueno',
                'dieta',
                'options',
                'gift',
                'resumen_voy',
                'te_espero',
                'end'
            ], novoy: [
                'novoy',
                'resumen_novoy',
                'end'
            ]};*/}
            
            <SideBar $open={SideBarOpen}>                
                <FaArrowLeft className='sideBarBurger'
                    onClick={() => {
                        //setSideBarOpen(!SideBarOpen)
                        goBack();
                    }}
                />
                {/*<GiHamburgerMenu size={20} className='sideBarList' style={{  }}
                    onClick={() => {
                        //setSideBarOpen(!SideBarOpen);
                    }}
                />
                SideBarOpen && <div className='bar'>
                    <img src={icon_home} alt='main' onClick={() => goHome()}/>
                    <img src={icon_fingerprint} alt='login' onClick={() => goProg('login')} />
                    <img src={icon_check} alt='confirm' onClick={() => goProg('confirm')} />
                    <img src={icon_food} alt='dieta' onClick={() => goProg('dieta')} />
                    {eventData.options && eventData.options.length > 0 && 
                        <img src={icon_options} alt='options' onClick={() => goProg('options')} />
                    }
                    { eventData.gift.enable && eventData.gift.alias && <img src={icon_gift} alt='gift' onClick={() => goProg('gift')}/> }
                    { eventData.cfg.sign && <img src={icon_signature} alt='sign' onClick={() => goProg('sign')}/>}
                    <img src={icon_dress_code} alt='te_espero'/>
                </div>}*/}
            </SideBar>
            <ImageBox>
                {/*<img alt={items[activeIndex].caption} src={items[activeIndex].image} />*/}
                <PanelContainer                
                    onAnimationEnd={onAnimationEnd}
                    onAnimationStart={onAnimationStart}            
                    $zoom={zoom} 
                    $movein={moveIn} 
                    $moveout={moveOut} 
                >
                    { panels[prog] }
                </PanelContainer>
                {/*<NavButton $position="left" onClick={moveTo(activeIndex - 1)}>
                    <FaChevronLeft />
                </NavButton>
                <NavButton $position="right" onClick={moveTo(activeIndex + 1)} >
                    <FaChevronRight />
                </NavButton>*/}
                {/*<ImageCaption $move={true}>{items[activeIndex].caption}</ImageCaption>*/}
            </ImageBox>
            {/*<ThumbnailList>
                {items.map((item, index) => (
                    <Thumbnail
                        key={index}
                        onClick={moveTo(index)}
                        $active={activeIndex === index}
                        src={item.image}
                    />
                ))}
            </ThumbnailList>*/}
            { eventData?.cfg?.countdown && 
                <StyledCountDown>
                    <CountDown 
                        //date={Date.now() + 10000} 
                        //Date.parse('04 Dec 1995 00:12:00 GMT');
                        //date={Date.parse('01/06/2024 21:30:00')}
                        date={Date.parse(eventData?.date_start)}
                        renderer={CoundownRenderer} 
                        updateFrequency={({ minutes, seconds }) => {
                            //minutes * 60 + seconds > 600 ? 5000 : 
                            return (1000)
                        }}                
                    />
                </StyledCountDown>
            }
            {/*<Loading src={loading3} alt='Loading...' />*/}                
        </MainDiv>
    );
};

//const cs = { n: '10vw', s: '4vw', t: '7vw', w: '18vw', h: '18vw', r: '2vw', col: '#fff', bkg: '#000' };
const cs = { n: '7vw', s: '3vw', t: '8vw', w: '15vw', h: '15vw', r: '2vw', col: '#fff', bkg: '#000', is: '7vw' };

const CoundownRenderer = ({ days, hours, minutes, seconds }) => (
    <div className='countDownContainer'>
        {/*<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', marginTop: '5px'}}>
            <div className='countDownText w3-secularOne'>FAL</div>
            <div className='countDownText w3-secularOne'>TAN</div>
        </div>*/}
        <div className='countDownLineContainer'>            
            <div className='countDownSquare'>            
                <div className='countDownNumber'>{days}</div> 
                <div className='countDownUnits'>DIAS</div>                 
            </div>
            <div className='countDownSquare' style={{ marginLeft: '10px',  marginRight: '10px' }}>            
                <div className='countDownNumber'>{hours}</div> 
                <div className='countDownUnits'>HRS</div> 
            </div>
            <div className='countDownSquare' style={{ marginRight: '10px' }}>            
                <div className='countDownNumber'>{minutes}</div> 
                <div className='countDownUnits'>MINS</div> 
            </div>
            <div className='countDownSquare'>            
                <div className='countDownNumber'>{seconds}</div> 
                <div className='countDownUnits'>SECS</div>                 
            </div>
        </div>
        <div className='countDownText'>FALTAN</div>        
    </div>
)
const Abutton = props => {
    return (
        <AButton {...props} >
            <img src={img_button} style={{ display: 'flex', width: '300px', minWidth: '300px', height: '5.5vh', maxHeight: '8vh' }} alt=''/>                        
            <label >
                {props.children}
            </label>            
        </AButton>
    )
}

const breatheAnimationOLD = keyframes`
    0% { opacity: 0.5 }
    20% { opacity: 0.75; }
    40% { opacity: 1; }
    50% { opacity: 1; }
    60% { opacity: 1; }
    80% { opacity: 0.75; }
    100% { opacity: 0.5; }
`
const breatheAnimation = keyframes`
    0% { opacity: 0.3 }
    //20% { opacity: 0.75; }
    //40% { opacity: 1; }
    50% { opacity: 1; }
    //60% { opacity: 1; }
    //80% { opacity: 0.75; }
    100% { opacity: 0.3; }
`
const AButton = styled.div`
    display: flex;
    flex-direction: column; 
    justify-content: center;
    //align-content: center;
    align-items: center;
    //position: relative;
    //min-width: 60vw;    
    //top: 40px;
    //height: calc(100vh - 40px);
    //background-color: #2ee;
    //color: #b056FF;
    //background-color: #00000080;
    //background-color: #ff5555a0;
    //color: #DCAFFF;
    color: ${theme.pricol};
    //font-family: Short Stack;
    font-family: Sofia;
    //font-family: Bebas Neue;
    font-size: 20px;
    &:hover {
        cursor: pointer;
        color: #701658;
        //background: #eee;
    }
    label {
        position: absolute;
        //position: relative;
        transform: { translateY: 3vh; };
        animation-name: ${props => props.$quiet ? '': breatheAnimation};    
        animation-duration: 1.5s;
        animation-iteration-count: infinite;    
        //top: -5.5vh;
    }
`;

const rotate = keyframes`
    to { transform: rotate(360deg); }
`
const flash = keyframes`
    from { opacity: 0; } to { opacity: 1; }
`;

const animIn = keyframes`
    from { transform: scale(0); } to { transform: scale(1); }
`;

const animOut = keyframes`
    from { transform: scale(1); } to { transform: scale(0); }
`;

const Loading = styled.img`
    width: 7vh;
    position: absolute;
    bottom: 15vh;
    animation: ${rotate} 1s linear infinite;
    visibility: ${props => props.$move ? 'visible': 'hidden'};
    //animation-name: rotate;
    //animation-duration: 1s;
    //animation-iteration-count: infinite;    
`;

const Loading2 = styled.img`
    width: 7vh;
    //position: absolute;
    //bottom: 15vh;
    margin-top: 20px;
    animation: ${rotate} 1s linear infinite;
    visibility: ${props => props.$move ? 'visible': 'hidden'};
    //animation-name: rotate;
    //animation-duration: 1s;
    //animation-iteration-count: infinite;    
`;

const SideBar = styled.div`  //div className="w3-sidebar w3-bar-block w3-black" 
    position: absolute;
	display: flex;
	flex-direction: column;
    justify-content: ${props => props.$open ? 'center': 'flex-start'};
    //align-content: center;
    align-items: center;
    z-index: 1000;
    left: 0;    
    top: 0;
    bottom: ${props => props.$open ? 0: 'undefined'};
    height: ${props => props.$open ? '100vh': '16vh'};
    padding-top: 10px;
    width: 10vw;
    //background-color: #000;
    .bar {
        position: absolute;
        top: 8vh;
        left: 0px;
        width: 10vw;
        height: ${props => props.$open ? '92vh': '0'};
        background-color: #000;

    }
    .sideBarBurger { 
        position: absolute;
        top: 1vh;
        left: 1vh;
        //color: #B056FF;
        color: ${theme.maincol};
        //width: ${cs.is};
        //height: ${cs.is}; 
        width: 4vh;
        height: 4vh; 
        //color: #eee;
        transform: { rotate:  ${props => props.$open ? '0deg': '90deg' }};         
    }
    .sideBarList {
        position: absolute;
        top: 5vh;
        left: 1vh;
        //color: #B056FF;
        color: ${theme.maincol};
        //width: ${cs.is};
        //height: ${cs.is}; 
        width: 4vh;
        height: 4vh; 
        //color: #eee;
    }
    img {
        display: flex;
        //flex-grow: 1;
        width: ${cs.is};
        height: ${cs.is}; 
        margin: auto;
        margin-top: 10px;
    }
`;

const MainDiv = styled.div`
    position: relative;
    width: 100vw;
    top: 0;
    //height: calc(100vh - 40px);
    height: 100vh;
    //background-color: #eee;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center; 
    cursor: pointer;
`;

const ImgBackGround = styled.img`
    position: absolute;
    top: 0;
    bottom: 0;
    //left: 0;    
    //right: 0;    
    height: 100%;
    //width: 100%;
    object-fit: cover;
`;

const ImageBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;        
    justify-content: center;                
    align-content: center;
    align-items: center;          
    //background-color: #eee;
    //color: #f43434;
    width: 100%;
    height: 100%;
    transform: ${({ $zoom }) => css`scale(${$zoom})`};
    animation: ${({ $moveIn, $moveOut, $zoom }) => $moveIn ? css`${animIn} 0.3s linear 1`: $moveOut ? css`${animOut} 0.3s linear 1`: css`transform: scale(${$zoom})` };    

    .zimg {
        position: absolute;
        margin: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: 100%;
        max-height: 100%;
        //transform: scale(${({ $zoom }) => $zoom});
        //transform: scale(0);
        //animation: ${({ $moveIn, $moveOut }) => $moveOut ? css`${animOut} 0.3s linear 1`: $moveIn ? css`${animIn} 0.3s linear 1`: null };    
        //animation-name: ${breatheAnimation};    
        //animation-duration: 8s;
        //animation-iteration-count: 3;    
    }  
    .addCalendar {
        display: flex;
        flex-direction: column;        
        justify-content: center;                
        align-content: center;
        align-items: center;          
        margin-bottom: 70px;
    }  
`;

//const cs = { n: '7vw', s: '3vw', t: '8vw', w: '15vw', h: '15vw', r: '2vw', col: '#fff', bkg: '#000', is: '7vw' };

const StyledCountDown = styled.div` 
    position: absolute;
    margin: auto;
    bottom: 1.5vh;
    font-family: Bebas Neue;
    //color: #B056FF;
    color: ${theme.maincol};
    //background-color: '';
    justify-content: center; 
    .countDownContainer {
        display: flex;
        flex-direction: column;        
        justify-content: center;
        align-content: center;
    }
    .countDownLineContainer {
        height: 9vh; 
        display: flex;
        flex-direction: row;        
        justify-content: center;                
        align-content: center;
    }
    .countDownContainer {
        display: flex;
        flex-grow: 1;
        justify-content: center; 
        align-content: center; 
        margin-top: 10px;
    }        
    .countDownSquare {        
        display: flex;
        flex-direction: column;        
        justify-content: space-around;                
        align-content: center;
        border-radius: 1vh;
        background-color: #ff5555a0;
        padding: 5px;
        width: 80px;    
    }
    .countDownNumber {
        display: flex;
        font-size: 35px;
        justify-content: center;
        align-content: center;
        line-height: 35px;
    }
    .countDownUnits {
        display: flex;
        font-size: 20px;
        justify-content: center;
        align-content: center;
        line-height: 20px;
    }
    .countDownText {
        margin: auto;
        margin-top: 15px;        
        margin-bottom: 15px;        
        font-size: 40px;
    }
`;

const StyledAdd2Calendar = styled.div` 
    display: flex;
    flex-direction: column;        
    justify-content: center;                
    align-content: center;
    align-items: center;          
    margin-bottom: 20px;
`;

const Opacity = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    //background-color: #000000a0;
    background-color: #000000;
    opacity: ${({ $opacity }) => $opacity || 0.5};
`;

const PanelContainer = styled.div`
    display: flex;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    //background-color: #eedd22;
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    max-height: 100%;
    transform: ${({ $zoom }) => css`scale(${$zoom})`};
    animation: ${({ $movein, $moveout, $zoom }) => $movein ? css`${animIn} 0.3s linear 1`: $moveout ? css`${animOut} 0.3s linear 1`: css`transform: scale(${$zoom})` };    
    button {
        display: flex;
        left: 45vw;
    }
    .labelMenu {
        font-size: 25px;        
        margin-top: 15px;
        //font-family: ${props => props.$fn || 'Florence'};
        font-family: ${props => props.$fn || 'Sofia'};    
        color: #DCAFFF;
    }
`;

const PanelLine = styled.div` 
    display: flex;    
    align-content: center;
    align-items: center;    
    justify-content: center;	
    flex-direction: column;        
    //font-family: ${props => props.$fn || 'Florence'};
    font-family: ${props => props.$fn || 'Sofia'};
    letter-spacing: 1.5px;
    //color: #eee;
    //color:  ${props => props.$color || '#eee'};
    color:  ${({ $color }) => $color || '#eee'};
    width: 100%;
    max-width: 100%;
    line-height: ${props => props.$fs || '4vw'};
    font-size: ${props => props.$fs || '4vw'};
    margin-top: ${props => props.$marginTop}
    margin-bottom: ${props => props.$marginBottom}
`;

const Panel = styled.div`
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    font-size: 20px;
    display: flex;    
    flex-direction: column;        
	justify-content: center;	
    align-content: center;
    align-items: center;    
    color: #fff;
    label {
        margin: 20px;
    }
    .itm {
        //background-color: #3eddd2;
        display: flex;        
        flex-direction: column;        
        align-content: center;
        align-items: center;
        justify-content: center;
    }
    .btn-social{
        min-width: 250px;
        width: 250px;
        //background: #7e2dd2;
    }        
    .itmrow {
        //background-color: #7e2dd2;
        display: flex;
        flex-direction: row;        
        align-content: space-between;        
        align-items: center;  
        height: 50px;      
        width: 70%;
        justify-content: space-between;        
    }
    .itmrow label {
        //background-color: #3e7742;
        line-height: 20px;
    } 
    .itmrow input {
        //background-color: #3e77d2;
        //line-height: 20px;
        margin-bottom: 10px;
    }
    ul {
        //background-color: #449922;
    }
    ul.li {
        display: flex;
        flex-direction: row;        
    }
    ul.li.input {
        margin: 30px
    }
    ul.li.label {
        margin: 30px
    }
`;

const ThumbnailList = styled.div`
    display: flex;
    align-items: stretch;
    width: 100%;
    height: 15%;
`;

const Thumbnail = styled.div`
    cursor: pointer;
    opacity: ${({ $active }) => ($active ? 1 : 0.6)};
    background-image: url(${({ src }) => src});
    background-size: cover;
    background-position: center;
    flex-grow: 1;
    //animation: ${rotate} 4s linear infinite;
    :hover {
        opacity: 1;
    }
`;

const NavButton = styled.button`
    cursor: pointer;
    position: absolute;
    top: 45%;
    padding: 5px;
    border-radius: 3px;
    border: none;
    background: rgba(255, 255, 255, 0.7);
    ${({ $position }) =>
        $position === 'left' &&
        css `left: 30px;`
    }
    ${({ $position }) =>
        $position === 'right' &&
        css `right: 30px;`
    }
    ${({ $position }) =>
        $position === 'center' &&
        css `top: 20px; left: 48vw;`
    }
`;

const NextButton = styled.div`
    cursor: pointer;
    margin-top: 20px;
    left: 50vw;
    text-align: center;
    width: 40vw;    
    min-width: 40vw;    
    color: #B056FF;
    padding: 15px;
    background: #2E0252;
    font-family: Short Stack;
    font-size: 4vw;
    border-radius: 1vw;
    //border-width: 2px;
    //border-color: #B056FF;
    border: 2px solid #B056FF;,
    &:hover {
        color: #222;
        background: #eee;
    }
    //background: rgba(255, 255, 255, 0.7);
    //position: absolute;
    //bottom: 5%;
    //padding: 5px;
    //border-radius: 3px;
    //border: none;
`;

const ImageCaption = styled.span`
    width: 100%;
    text-align: center;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    padding: 8px;
    background: rgba(255, 255, 255, 0.7);
    //animation: ${({ $move }) => $move && css`${flash} 0.3s linear 3` };    
`;

export default View;

