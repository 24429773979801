import React, { createContext, useState, useRef, useEffect } from "react";
//import { io } from "socket.io-client";
//import Peer from "simple-peer";
import { config } from './config.js' // component display user (see detail on /example directory)
import _ from 'lodash';
/*
 "@types/react": "^18.2.73",
    "@typescript-eslint/parser": "^7.4.0"
*/
var _timerAlert = null;
//const API_URL = 'https://mievent.info/api';
const SocketContext = createContext();

/*const socket = io(config.CONNECTION_LINK, {
    transports: ['websocket'], 
    path: '/ws',
    //transports: ['polling', 'websocket'] 
    //'reconnection': true,
    'reconnectionDelay': 1000,
    'reconnectionDelayMax': 3000,
    //'forceNew': true						
    query: { token: 'abc' }, 
});*/

const eventdata = {
    url: 'https://mievent.info/xv_de_belu',
    res: {
        imgs: [],
        vids: [],
        music: []
    },    
    gift: {
        enabled: true,
        alias: 'belen.cuerva.mp',
        cvu: '',
        cbu: '',
    },    
    options: [
        //{ text: 'Quiero bailar el vals!', key: 'vals' },
        //{ text: 'Quiero entrar al pogo!', key: 'pogo' },
        //{ text: 'Quiero torta!', key: 'torta' },
    ],
    anfi: {
        name: "Belu"
    },
    /*fecha: '1/6',
    dia: 'sábado',
    hora: '21:30',*/
    date_start: '2024-06-01T21:30:00-03:00',
    date_end: '2024-06-02T05:30:00-03:00',
    dresscode: {
        //ellas: 'Elegante, not blue',
        //ellos: 'Elegante, green',
        ellas: '',
        ellos: '',
        not: 'Elegant, not blue'
    },
    event: {
        name: 'XV de Belu',
        type: 'XV',
    },
    place: {
        name: 'Janos Puerto Madero',
        dire: 'Olga Cosettini 1031',
        latlng: {
            lat: -34.60959133726496,
            lng: -58.363879376880895
        }
    },    
    alta: 0,
    baja: 0,
    form: { loaded: false, dia: '', fecha: '', hora: '' },
    //loginOptions: ["google", "instagram", "apple", "microsoft", "facebook"],
    loginOptions: {google: true, instagram: false, apple: false, microsoft: false, facebook: false},
    program: {},    
    cfg: { countdown: true, sign: true, gift: true },
}

const ContextProvider = ({ children }) => {
    const [callAccepted, setCallAccepted] = useState(false);
    const [isAudioAvailable, setAudioAvailability] = useState(false);
    const [isVideoAvailable, setVideoAvailability] = useState(false);
    const [callEnded, setCallEnded] = useState(false);
    const [stream, setStream] = useState();
    const [name, setName] = useState("");
    const [call, setCall] = useState({});
    const [user, setUser] = useState({});
    const [event, setEvent] = useState('');
    const [profile, setProfile] = useState({});
    const [provider, setProvider] = useState("");
    const [me, setMe] = useState("");
    const [group, setGroup] = useState("");
    const [other, setOther] = useState("");
    const [theme, setTheme] = useState('dark');
    const myVideo = useRef();
    const userVideo = useRef();
    const connectionRef = useRef();
    const [eventData, setEventData] = useState(eventdata);   

    /*useEffect(() => {
        setStream(null);
        if (isVideoAvailable || isAudioAvailable) {
            navigator.mediaDevices
                .getUserMedia({ video: isVideoAvailable, audio: isAudioAvailable })
                .then(currentStream => {
                    setStream(currentStream);
                    //console.log('isVideoAvailable ' + isVideoAvailable + ' myVideo?.current ' + myVideo?.current);
                    if (isVideoAvailable) { //&& myVideo && myVideo.current) {
                        _.delay(() => { myVideo.current.srcObject = currentStream;}, 1500);
                    } 
                });
        }
        if (!isVideoAvailable) {
            stream?.getVideoTracks()[0]?.stop();
        }
    }, [isVideoAvailable, isAudioAvailable]);
     
    useEffect(() => {
        socket.on("me", id => setMe(id));
        socket.on("calluser", ({ from, name: callerName, signal }) => {
            setCall({ isReceivingCall: true, from, name: callerName, signal });
        });
    }, []);
     
    const answerCall = () => {
        setCallAccepted(true);
        const peer = new Peer({ initiator: false, trickle: false, stream });
        peer.on("signal", data => {
            socket.emit("answercall", { signal: data, to: call.from });
        });
        peer.on("stream", currentStream => {
            userVideo.current.srcObject = currentStream;
        });
        peer.signal(call.signal);
        connectionRef.current = peer;
    };

    const callUser = id => {
        const peer = new Peer({ initiator: true, trickle: false, stream });
        peer.on("signal", data => {
            socket.emit("calluser", {
                userToCall: id,
                signalData: data,
                from: me,
                name
            });
        });
        peer.on("stream", currentStream => {
            userVideo.current.srcObject = currentStream;
        });
        socket.on("callaccepted", signal => {
            setCallAccepted(true);
            peer.signal(signal);
        });
        connectionRef.current = peer;
    };

    const leaveCall = () => {
        setCallEnded(true);
        connectionRef.current.destroy();
        window.location.reload();
    };

    React.useEffect(() => {
        if (event) {
            
        }
    }, [event]);*/
    
    const _fetchCall = ({ data, endpoint, tomsg, method='get', showmsg, onok, onerr, usews=true }) => { 
		console.log('_fetchCall endpoint: ' + endpoint + ' usews: ' + usews + ' data: ' + JSON.stringify(data));		
		if (!data || !endpoint) { return onerr({ msg: 'Error ' + tomsg + ' wrong call' }); }
		const controller = new AbortController();            
		_timerAlert && clearTimeout(_timerAlert);
		_timerAlert = setTimeout(() => {
			controller.abort();
			//this.props.set_activity({ txt: '' });
			if (_.isFunction(tomsg)) {
				tomsg();
			} else {
				tomsg && alert( 'Error ' + tomsg + ', sin respuesta', '', [{ text: 'Aceptar' }])														
			}
			onerr({ msg: 'timeout' });
		}, config.FETCH_TIMEOUT);                    
		//showmsg && this.props.set_activity({ txt: showmsg });
        fetch(config.API_URL + endpoint, {				
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            method,
            body: JSON.stringify(data)
        }).then(response => response.json())    
        .then(responseData => {
            console.log('_fetchCall response: ' + JSON.stringify(responseData));		
            _timerAlert && clearTimeout(_timerAlert);
            //this.props.set_activity({ txt: '' });
            onok(responseData);            
        }).catch(err => {
            console.log('_fetchCall err: ' + JSON.stringify(err));		
            _timerAlert && clearTimeout(_timerAlert);
            //this.props.set_activity({ txt: '' });
            //controller.abort();
            onerr(err);
        });		        
	}

    return (
        <SocketContext.Provider
            value={{
                isAudioAvailable,
                isVideoAvailable,
                setAudioAvailability,
                setVideoAvailability,
                call,
                callAccepted,
                myVideo,
                userVideo,
                stream,
                name,
                setName,
                group,
                setGroup,
                user,
                setUser,
                profile, 
                setProfile,
                provider, 
                setProvider,        
                event, 
                setEvent,
                callEnded,
                me,
                other,
                setOther,
                theme,
                setTheme,
                fetchCall: _fetchCall,
                //sendConfirm: _sendConfirm,
                eventData,
                setEventData,
            }}
        >
            { children }
        </SocketContext.Provider>
    );
};

export { ContextProvider, SocketContext };
