import styled, { createGlobalStyle } from 'styled-components'

export const CSSReset = createGlobalStyle`
  *,
  *::before, 
  *::after {
        margin: 0; 
        padding: 0;
        box-sizing: inherit;
    }
    html {
        font-size: 62.5%; /*1rem = 10px*/
        box-sizing: border-box;    
        cursor: pointer;
    }  
    body {
        font-size: 1.4rem;
        font-family: sans-serif;  
        //line-height: 1.5;
        line-height: calc(1em + 0.5rem);
        -webkit-font-smoothing: antialiased;
        cursor: pointer;
    }
    img, picture, video, canvas, svg {
        display: block;
        max-width: 100%;
    }    
    input, button, textarea, select {
        font: inherit;
    }
    /*
        7. Avoid text overflows
    */
    p, h1, h2, h3, h4, h5, h6 {
        overflow-wrap: break-word;
    }
    /*
        8. Create a root stacking context
    */
    #root, #__next {
        isolation: isolate;
    }    
`;

export const Styles = {
	Wrapper: styled.main`
        display: flex;
        width: 100vw;
        background-color: #333;
        height: 100vh;
    `,
};

export const GlobalStyle = createGlobalStyle`
    body {
        background: #333;
    }
    .fieldText {
        color: #61dafb;
        height: 10vh;
    }            
    .fieldOption {
        color: #61dafb;
        height: 10vh;
    }            
    .loader {
        border: 16px solid #f3f3f3;
        border-top: 16px solid #3498db;
        border-radius: 50%;
        width: 130px;
        height: 130px;
        animation: spin 2s linear infinite;
    }      
    @keyframes spin {
        0%  { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    .loading-screen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //background-color: #6dc8cf;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }       
    .loading-spinner {
        border: 7px solid #f3f3f3;
        border-top: 7px solid #483d8b; //#f7ae65;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        animation: spin 1.5s linear infinite;
    }    
    .q-menu-item {
        color: #99f;
        padding-left: 10px;
    }      
    .q-menu-add-item {
        margin-right: 15px;
        color: #7f7;
    }
    .q-menu-trash-item {
        margin-right: 0px;
        color: #f77;
    }
    .q-hover-scale {
        cursor: pointer;
        :hover {
            transform: scale(1.2);
            margin-right: 20px;
            border-radius: 1.6rem;
            background-color: #000;
        }        
    }
    .q-main-wrapper {
        display: flex;
        width: 100vw;
        background-color: #333;
        height: 100vh;
    }
`;

